<div *ngIf="loading"
     class="loading-overlay">
    <div class="spinner"></div>
    <h3 class="mt-3 ml-1 text-info ">Generando datos de facturación...</h3>
</div>


<!-- Salidas -->
<span *ngIf="datosFacturacion.length">
    <div class="card border border-info rounded">
        <div class="card-body">

            <div class="d-flex justify-content-between align-items-center">
                <h2>Salidas</h2>
                <button class="btn btn-seccondary mb-2"
                        [disabled]="loading"
                        (click)="abrirPdfClick()">
                    <i class="fa fa-spinner fa-spin"
                       *ngIf="loading"></i> Abrir detalle</button>
            </div>

            <!-- RESUMEN -->

            <table class="table table-sm table-striped table-bordered table-hover">

                <thead>
                    <tr>
                        <th class="text-info"
                            style="text-align: right;">Totales de {{ totales.salidas.porSalida.Cantidad | number: '0.0':'es'}} Pedidos enviados </th>
                        <th style="text-align: right;">Cantidad </th>
                        <th style="text-align: right;">Precio </th>
                        <th style="text-align: right;">Importe </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="text-align: right;">Líneas Mínimo</td>
                        <td style="text-align: right;">{{ totales.salidas.lineasMinimo.Cantidad | number: '0.0':'es'}}</td>
                        <td style="text-align: right;">{{ totales.salidas.lineasMinimo.Precio | euroCurrency }} </td>
                        <td style="text-align: right;">{{ totales.salidas.lineasMinimo.Importe | euroCurrency }} </td>
                    </tr>

                    <tr>
                        <td style="text-align: right;">Líneas Adicionales</td>
                        <td style="text-align: right;">{{ totales.salidas.lineasAdicionales.Cantidad | number: '0.0':'es' }}</td>
                        <td style="text-align: right;">{{ totales.salidas.lineasAdicionales.Precio | euroCurrency }}</td>
                        <td style="text-align: right;">{{ totales.salidas.lineasAdicionales.Importe | euroCurrency}}</td>
                    </tr>

                    <tr>
                        <td style="text-align: right;">Por unidad</td>
                        <td style="text-align: right;">{{ totales.salidas.unidades.Cantidad | number: '0.0':'es' }}</td>
                        <td style="text-align: right;">{{ totales.salidas.unidades.Precio | euroCurrency }}</td>
                        <td style="text-align: right;">{{ totales.salidas.unidades.Importe | euroCurrency}}</td>
                    </tr>

                    <tr>
                        <td style="text-align: right;">Horas empleadas</td>
                        <td style="text-align: right;">{{ totales.salidas.horas.Cantidad | number: '0.0':'es' }}</td>
                        <td style="text-align: right;">{{ totales.salidas.horas.Precio | euroCurrency }}</td>
                        <td style="text-align: right;">{{ totales.salidas.horas.Importe | euroCurrency}}</td>
                    </tr>

                    <tr>
                        <td style="text-align: right;">Por salida</td>
                        <td style="text-align: right;">{{ totales.salidas.porSalida.Cantidad | number: '0.0':'es' }}</td>
                        <td style="text-align: right;">{{ totales.salidas.porSalida.Precio | euroCurrency }}</td>
                        <td style="text-align: right;">{{ totales.salidas.porSalida.Importe | euroCurrency}}</td>
                    </tr>

                    <tr *ngIf="totales.salidas.lineasConLote.Importe > 0 ">
                        <td style="text-align: right;">Líneas que gestionan lote</td>
                        <td style="text-align: right;">{{ totales.salidas.lineasConLote.Cantidad | number: '0.0':'es' }}</td>
                        <td style="text-align: right;">{{ totales.salidas.lineasConLote.Precio | euroCurrency }}</td>
                        <td style="text-align: right;">{{ totales.salidas.lineasConLote.Importe | euroCurrency}}</td>
                    </tr>

                    <tr *ngIf="totales.salidas.porPales.Importe > 0 ">
                        <td style="text-align: right;">Por palés</td>
                        <td style="text-align: right;">{{ totales.salidas.porPales.Cantidad | number: '0.0':'es' }}</td>
                        <td style="text-align: right;">{{ totales.salidas.porPales.Precio | euroCurrency }}</td>
                        <td style="text-align: right;">{{ totales.salidas.porPales.Importe | euroCurrency}}</td>
                    </tr>


                    <tr>
                        <td class="text-info font-weight-bold"
                            style="text-align: right;">PACKAGING </td>
                        <td style="text-align: right;"> </td>
                        <td style="text-align: right;"> </td>
                        <td style="text-align: right;"> </td>
                    </tr>

                    <tr *ngIf="totales.salidas.pale01.Importe >0 ">
                        <td style="text-align: right;">Palé NO retornable</td>
                        <td style="text-align: right;">{{ totales.salidas.pale01.Cantidad | number: '0.0':'es'}}</td>
                        <td style="text-align: right;">{{ totales.salidas.pale01.Precio | euroCurrency }} </td>
                        <td style="text-align: right;">{{ totales.salidas.pale01.Importe | euroCurrency }} </td>
                    </tr>
                    <tr *ngIf="totales.salidas.pale02.Importe >0 ">
                        <td style="text-align: right;">Palé Europeo</td>
                        <td style="text-align: right;">{{ totales.salidas.pale02.Cantidad | number: '0.0':'es'}}</td>
                        <td style="text-align: right;">{{ totales.salidas.pale02.Precio | euroCurrency }} </td>
                        <td style="text-align: right;">{{ totales.salidas.pale02.Importe | euroCurrency }} </td>
                    </tr>
                    <tr *ngIf="totales.salidas.pale03.Importe >0 ">
                        <td style="text-align: right;">Palé Americano</td>
                        <td style="text-align: right;">{{ totales.salidas.pale03.Cantidad | number: '0.0':'es'}}</td>
                        <td style="text-align: right;">{{ totales.salidas.pale03.Precio | euroCurrency }} </td>
                        <td style="text-align: right;">{{ totales.salidas.pale03.Importe | euroCurrency }} </td>
                    </tr>
                    <tr *ngIf="totales.salidas.pale04.Importe >0 ">
                        <td style="text-align: right;">Palé plástico</td>
                        <td style="text-align: right;">{{ totales.salidas.pale04.Cantidad | number: '0.0':'es'}}</td>
                        <td style="text-align: right;">{{ totales.salidas.pale04.Precio | euroCurrency }} </td>
                        <td style="text-align: right;">{{ totales.salidas.pale04.Importe | euroCurrency }} </td>
                    </tr>
                    <tr *ngIf="totales.salidas.pale05.Importe >0 ">
                        <td style="text-align: right;">Palé Ignífugo</td>
                        <td style="text-align: right;">{{ totales.salidas.pale05.Cantidad | number: '0.0':'es'}}</td>
                        <td style="text-align: right;">{{ totales.salidas.pale05.Precio | euroCurrency }} </td>
                        <td style="text-align: right;">{{ totales.salidas.pale05.Importe | euroCurrency }} </td>
                    </tr>


                    <tr *ngIf="totales.salidas.caja01.Importe >0 ">
                        <td style="text-align: right;">Cajas Pequeñas</td>
                        <td style="text-align: right;">{{ totales.salidas.caja01.Cantidad | number: '0.0':'es'}}</td>
                        <td style="text-align: right;">{{ totales.salidas.caja01.Precio | euroCurrency }} </td>
                        <td style="text-align: right;">{{ totales.salidas.caja01.Importe | euroCurrency }} </td>
                    </tr>
                    <tr *ngIf="totales.salidas.caja02.Importe >0 ">
                        <td style="text-align: right;">Cajas Medianas</td>
                        <td style="text-align: right;">{{ totales.salidas.caja02.Cantidad | number: '0.0':'es'}}</td>
                        <td style="text-align: right;">{{ totales.salidas.caja02.Precio | euroCurrency }} </td>
                        <td style="text-align: right;">{{ totales.salidas.caja02.Importe | euroCurrency }} </td>
                    </tr>
                    <tr *ngIf="totales.salidas.caja03.Importe >0 ">
                        <td style="text-align: right;">Cajas Grandes</td>
                        <td style="text-align: right;">{{ totales.salidas.caja03.Cantidad | number: '0.0':'es'}}</td>
                        <td style="text-align: right;">{{ totales.salidas.caja03.Precio | euroCurrency }} </td>
                        <td style="text-align: right;">{{ totales.salidas.caja03.Importe | euroCurrency }} </td>
                    </tr>


                    <tr *ngIf="totales.salidas.bolsa01.Importe >0 ">
                        <td style="text-align: right;">Bolsas Pequeñas</td>
                        <td style="text-align: right;">{{ totales.salidas.bolsa01.Cantidad | number: '0.0':'es'}}</td>
                        <td style="text-align: right;">{{ totales.salidas.bolsa01.Precio | euroCurrency }} </td>
                        <td style="text-align: right;">{{ totales.salidas.bolsa01.Importe | euroCurrency }} </td>
                    </tr>
                    <tr *ngIf="totales.salidas.bolsa02.Importe >0 ">
                        <td style="text-align: right;">Bolsas Medianas</td>
                        <td style="text-align: right;">{{ totales.salidas.bolsa02.Cantidad | number: '0.0':'es'}}</td>
                        <td style="text-align: right;">{{ totales.salidas.bolsa02.Precio | euroCurrency }} </td>
                        <td style="text-align: right;">{{ totales.salidas.bolsa02.Importe | euroCurrency }} </td>
                    </tr>
                    <tr *ngIf="totales.salidas.bolsa03.Importe >0 ">
                        <td style="text-align: right;">Bolsas Grandes</td>
                        <td style="text-align: right;">{{ totales.salidas.bolsa03.Cantidad | number: '0.0':'es'}}</td>
                        <td style="text-align: right;">{{ totales.salidas.bolsa03.Precio | euroCurrency }} </td>
                        <td style="text-align: right;">{{ totales.salidas.bolsa03.Importe | euroCurrency }} </td>
                    </tr>


                    <tr *ngIf="totales.salidas.burbuja.Importe >0 ">
                        <td style="text-align: right;">Burbuja</td>
                        <td style="text-align: right;">{{ totales.salidas.burbuja.Cantidad | number: '0.0':'es'}}</td>
                        <td style="text-align: right;">{{ totales.salidas.burbuja.Precio | euroCurrency }} </td>
                        <td style="text-align: right;">{{ totales.salidas.burbuja.Importe | euroCurrency }} </td>
                    </tr>
                    <tr *ngIf="totales.salidas.viruta.Importe >0 ">
                        <td style="text-align: right;">Viruta</td>
                        <td style="text-align: right;">{{ totales.salidas.viruta.Cantidad | number: '0.0':'es'}}</td>
                        <td style="text-align: right;">{{ totales.salidas.viruta.Precio | euroCurrency }} </td>
                        <td style="text-align: right;">{{ totales.salidas.viruta.Importe | euroCurrency }} </td>
                    </tr>
                    <tr *ngIf="totales.salidas.papel.Importe >0 ">
                        <td style="text-align: right;">Papel Kraft</td>
                        <td style="text-align: right;">{{ totales.salidas.papel.Cantidad | number: '0.0':'es'}}</td>
                        <td style="text-align: right;">{{ totales.salidas.papel.Precio | euroCurrency }} </td>
                        <td style="text-align: right;">{{ totales.salidas.papel.Importe | euroCurrency }} </td>
                    </tr>
                    <tr *ngIf="totales.salidas.otros.Importe >0 ">
                        <td style="text-align: right;">Otros</td>
                        <td style="text-align: right;">{{ totales.salidas.otros.Cantidad | number: '0.0':'es'}}</td>
                        <td style="text-align: right;">{{ totales.salidas.otros.Precio | euroCurrency }} </td>
                        <td style="text-align: right;">{{ totales.salidas.otros.Importe | euroCurrency }} </td>
                    </tr>
                </tbody>

                <tfoot>
                    <tr class="total-row">
                        <td style="text-align: right;"><strong>Total</strong></td>
                        <td></td>
                        <td></td>
                        <td style="text-align: right;"><strong>{{ totales.salidas._sumas.Importe | euroCurrency }}</strong></td>
                    </tr>
                </tfoot>
            </table>



        </div>
    </div>
</span>


<!-- Sin datos -->
<span *ngIf="sinDatos">
    <div class="card border border-info rounded">
        <div class="card-body">
            <h2>SALIDAS</h2>
            <h3 class="text-success"> Sin Datos</h3>
        </div>
    </div>
</span>