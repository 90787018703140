import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// servicios
import { AlmacenesService } from 'src/app/services/datos/almacenes.service';
import { UsuarioService } from 'src/app/services/datos/usuario.service';
import { UbicacionesService } from 'src/app/services/datos/ubicaciones.service';

// interfaces y modelos
import { IAlmacen } from 'src/app/interfaces/almacenes';

// Libs
import CustomVal from 'src/app/providers/CustomValidators';
import FuncLib from 'src/app/providers/funcionesLib';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

@Component({
  selector: 'app-ubicaciones-labels',
  templateUrl: './ubicaciones-labels.component.html',
  styleUrls: ['./ubicaciones-labels.component.scss']
})
export class UbicacionesLabelsComponent implements OnInit {

  public enviado: boolean = false;
  public loading: boolean = false;

  private funcLib: FuncLib = new FuncLib();
  public infoUbiDesde: string = '';
  public infoUbiHasta: string = '';

  almacenesList: IAlmacen[] = [];
  ubicacionesList: any[] = [];

  public labelForm: FormGroup = new FormGroup({});

  constructor(
    private ubicacionesSrv: UbicacionesService,
    private almacenesSrv: AlmacenesService,
    private fb: FormBuilder,
    private usuarioSrv: UsuarioService,
    public notificacionesSrv: NotificacionesService
  ) { }
  ngOnInit(): void {

    // Declara el fom reactivo
    this.labelForm = this.fb.group({
      id_almacen: [this.usuarioSrv.userdata.alm_por_defecto, [Validators.required]],
      almacen_desde: ['', [Validators.minLength(12), Validators.maxLength(12)]],
      almacen_hasta: ['', [Validators.minLength(12), Validators.maxLength(12)]],
    })

    this.getAlmacenesList();

  }


  enviar() {
    this.enviado = true;
    this.loading = true;

    const data: any = {
      id_usuario: this.usuarioSrv.userdata.id,
      id_almacen: this.labelForm.get('id_almacen')?.value,
      almacen_desde: this.labelForm.get('almacen_desde')?.value,
      almacen_hasta: this.labelForm.get('almacen_hasta')?.value,
    }

    this.ubicacionesSrv.ubiLabel(data)
      .subscribe(resp => {
        this.loading = false;
        console.log('__ubiLabel data', data);
        console.log('__ubiLabel resp', resp);
        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
          for (let controlName in resp.mensaje) {
            this.labelForm.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
          }
          return
        }
        this.notificacionesSrv.aviso('success', resp.mensaje);
        this.ubicacionesList = resp.data.ubicacionesList;
        console.log('___.ubicacionesList', this.ubicacionesList);
        return
      })
  }


  // Get lista de Almacenes
  getAlmacenesList() {
    this.loading = true;
    this.almacenesSrv.getLista()
      .subscribe(respuesta => {
        this.loading = false;
        this.almacenesList = respuesta.data;
      })
  }



  // AUX

  cambiaUbiDesde() {
    const ubicacion = this.labelForm.get('almacen_desde')?.value;
    this.infoUbiDesde = this.funcLib.CodUbiDecript(ubicacion);
  }

  cambiaUbiHasta() {
    const ubicacion = this.labelForm.get('almacen_hasta')?.value;
    this.infoUbiHasta = this.funcLib.CodUbiDecript(ubicacion);
  }



  get f() {
    return this.labelForm.controls;
  }



}
