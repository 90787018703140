import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

// Interfaces / Modelos
import { ICliente } from 'src/app/interfaces/cliente';
import { IUbiTipo } from 'src/app/interfaces/ubicacion';
import { IUsuario } from 'src/app/interfaces/usuario';


// Servicios
import { ClientesService } from 'src/app/services/datos/clientes.service';
import { UbiTiposService } from 'src/app/services/datos/ubi_tipos.service';
import { UsuarioService } from 'src/app/services/datos/usuario.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';



@Component({
  selector: 'app-cliente-editfac',
  templateUrl: './cliente-editfac.component.html',
  styleUrls: ['./cliente-editfac.component.scss']
})
export class ClienteEditfacComponent implements OnInit {

  public cliente: ICliente = {}
  paramId: string = '';
  public muestrafacDia: boolean = true

  usuariosList: IUsuario[] = [];

  tiposPaleList: any[] = [];
  public paleTipo1: IUbiTipo = {}
  public paleTipo2: IUbiTipo = {}

  public cliForm: FormGroup = new FormGroup({});

  public enviado: boolean = false;
  public loading: boolean = false;


  constructor(
    private clienteSrv: ClientesService,
    private ubiTiposSrv: UbiTiposService,
    public usuarioSrv: UsuarioService,
    private notificacionesService: NotificacionesService,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,) { }

  public serviciosList: any[] = [];

  ngOnInit(): void {

    // Suscribirse a los parámetros de la url, si envían una id, cargar los datos del cliente
    this.activatedRoute.params.subscribe(({ id }) => {
      this.paramId = id;
      this.cargarCliente(id);
    })

    // Tipos de palé
    this.getTiposPale()

    // Declara el fom reactivo
    this.cliForm = this.fb.group({

      fac_entrada_MinLin: [''],
      fac_entrada_MinPrecio: [''],
      fac_entrada_LinPrecio: [''],
      fac_entrada_UnidPrecio: [''],
      fac_entrada_PalePrecio: [''],
      fac_entrada_gestLotePrecio: [''],
      fac_entrada_PrecioHora: [''],
      fac_entrada_PrecioEtiqueta: [''],
      fac_entrada_PrecioContainer20: [''],
      fac_entrada_PrecioContainer40: [''],
      fac_entrada_PrecioFijo: [''],

      fac_salida_MinLin: [''],
      fac_salida_MinPrecio: [''],
      fac_salida_LinPrecio: [''],
      fac_salida_UnidPrecio: [''],
      fac_salida_PalePrecio: [''],
      fac_salida_gestLotePrecio: [''],
      fac_salida_PrecioHora: [''],
      fac_salida_PrecioEtiqueta: [''],
      fac_salida_PrecioFijo: [''],

      fac_pack_PrecioFijo: [''],

      fac_pack_bolsa01: [''],
      fac_pack_bolsa02: [''],
      fac_pack_bolsa03: [''],

      fac_pack_caja01: [''],
      fac_pack_caja02: [''],
      fac_pack_caja03: [''],

      fac_pack_burbuja: [''],
      fac_pack_viruta: [''],
      fac_pack_papel: [''],
      fac_pack_otros: [''],

      fac_pack_pale01: [''],
      fac_pack_pale02: [''],
      fac_pack_pale03: [''],
      fac_pack_pale04: [''],
      fac_pack_pale05: [''],

      fac_pack_horas: [''],

      fac_seguro_eur: [''],
      fac_seguro_valor: [''],
      fac_seguro_porcentaje: [''],
      fac_seguro_calculado: [''],

      fac_movimiento_pale: [''],
      fac_movimiento_referencia: [''],

      fac_devol_MinLin: [''],
      fac_devol_MinPrecio: [''],
      fac_devol_LinPrecio: [''],
      fac_devol_UnidPrecio: [''],
      fac_devol_PrecioFijo: [''],
      fac_devol_PalePrecio: [''],
      fac_devol_gestLotePrecio: [''],
      fac_devol_PrecioHora: [''],
      fac_devol_PrecioEtiqueta: [''],

      fac_alma_dia: [''],
      fac_alma_finMes: [''],


      fac_espa_pale1_tipo: [''],
      fac_espa_pale2_tipo: [''],
      fac_espa_cajetin_dia: [''],
      fac_espa_pale1_dia: [''],
      fac_espa_pale2_dia: [''],
      fac_espa_cajetin_mes: [''],
      fac_espa_cajetin_unidadesMes: [''],
      fac_espa_pale1_mes: [''],
      fac_espa_pale1_unidadesMes: [''],
      fac_espa_pale2_mes: [''],
      fac_espa_pale2_unidadesMes: [''],

    })

  }

  cargarCliente(id: number) {
    this.loading = true;
    this.clienteSrv.getCliente(id)
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notificacionesService.aviso('error', resp.mensaje);
          this, this.usuarioSrv.logOut();
        } else {
          this.cliente = resp.data;

          this.cliForm.patchValue({

            fac_entrada_MinLin: this.cliente.fac_entrada_MinLin,
            fac_entrada_MinPrecio: this.cliente.fac_entrada_MinPrecio,
            fac_entrada_LinPrecio: this.cliente.fac_entrada_LinPrecio,
            fac_entrada_UnidPrecio: this.cliente.fac_entrada_UnidPrecio,
            fac_entrada_PalePrecio: this.cliente.fac_entrada_PalePrecio,
            fac_entrada_gestLotePrecio: this.cliente.fac_entrada_gestLotePrecio,
            fac_entrada_PrecioHora: this.cliente.fac_entrada_PrecioHora,
            fac_entrada_PrecioEtiqueta: this.cliente.fac_entrada_PrecioEtiqueta,
            fac_entrada_PrecioContainer20: this.cliente.fac_entrada_PrecioContainer20,
            fac_entrada_PrecioContainer40: this.cliente.fac_entrada_PrecioContainer40,
            fac_entrada_PrecioFijo: this.cliente.fac_entrada_PrecioFijo,

            fac_salida_MinLin: this.cliente.fac_salida_MinLin,
            fac_salida_MinPrecio: this.cliente.fac_salida_MinPrecio,
            fac_salida_LinPrecio: this.cliente.fac_salida_LinPrecio,
            fac_salida_UnidPrecio: this.cliente.fac_salida_UnidPrecio,
            fac_salida_PalePrecio: this.cliente.fac_salida_PalePrecio,
            fac_salida_gestLotePrecio: this.cliente.fac_salida_gestLotePrecio,
            fac_salida_PrecioHora: this.cliente.fac_salida_PrecioHora,
            fac_salida_PrecioEtiqueta: this.cliente.fac_salida_PrecioEtiqueta,
            fac_salida_PrecioFijo: this.cliente.fac_salida_PrecioFijo,

            fac_pack_PrecioFijo: this.cliente.fac_pack_PrecioFijo,

            fac_pack_bolsa01: this.cliente.fac_pack_bolsa01,
            fac_pack_bolsa02: this.cliente.fac_pack_bolsa02,
            fac_pack_bolsa03: this.cliente.fac_pack_bolsa03,

            fac_pack_caja01: this.cliente.fac_pack_caja01,
            fac_pack_caja02: this.cliente.fac_pack_caja02,
            fac_pack_caja03: this.cliente.fac_pack_caja03,

            fac_pack_burbuja: this.cliente.fac_pack_burbuja,
            fac_pack_viruta: this.cliente.fac_pack_viruta,
            fac_pack_papel: this.cliente.fac_pack_papel,
            fac_pack_otros: this.cliente.fac_pack_otros,

            fac_pack_pale01: this.cliente.fac_pack_pale01,
            fac_pack_pale02: this.cliente.fac_pack_pale02,
            fac_pack_pale03: this.cliente.fac_pack_pale03,
            fac_pack_pale04: this.cliente.fac_pack_pale04,
            fac_pack_pale05: this.cliente.fac_pack_pale05,

            fac_pack_horas: this.cliente.fac_pack_horas,

            fac_seguro_eur: this.cliente.fac_seguro_eur,
            fac_seguro_valor: this.cliente.fac_seguro_valor,
            fac_seguro_porcentaje: this.cliente.fac_seguro_porcentaje,
            fac_seguro_calculado: this.cliente.fac_seguro_calculado,

            fac_movimiento_pale: this.cliente.fac_movimiento_pale,
            fac_movimiento_referencia: this.cliente.fac_movimiento_referencia,

            fac_devol_MinLin: this.cliente.fac_devol_MinLin,
            fac_devol_MinPrecio: this.cliente.fac_devol_MinPrecio,
            fac_devol_LinPrecio: this.cliente.fac_devol_LinPrecio,
            fac_devol_UnidPrecio: this.cliente.fac_devol_UnidPrecio,
            fac_devol_PrecioFijo: this.cliente.fac_devol_PrecioFijo,
            fac_devol_PalePrecio: this.cliente.fac_devol_PalePrecio,
            fac_devol_gestLotePrecio: this.cliente.fac_devol_gestLotePrecio,
            fac_devol_PrecioHora: this.cliente.fac_devol_PrecioHora,
            fac_devol_PrecioEtiqueta: this.cliente.fac_devol_PrecioEtiqueta,

            fac_alma_dia: this.cliente.fac_alma_dia,
            fac_alma_finMes: this.cliente.fac_alma_finMes,

            fac_espa_pale1_tipo: this.cliente.fac_espa_pale1_tipo,
            fac_espa_pale2_tipo: this.cliente.fac_espa_pale2_tipo,
            fac_espa_cajetin_dia: this.cliente.fac_espa_cajetin_dia,

            fac_espa_pale1_dia: this.cliente.fac_espa_pale1_dia,
            fac_espa_pale2_dia: this.cliente.fac_espa_pale2_dia,
            fac_espa_cajetin_mes: this.cliente.fac_espa_cajetin_mes,
            fac_espa_cajetin_unidadesMes: this.cliente.fac_espa_cajetin_unidadesMes,
            fac_espa_pale1_mes: this.cliente.fac_espa_pale1_mes,
            fac_espa_pale1_unidadesMes: this.cliente.fac_espa_pale1_unidadesMes,
            fac_espa_pale2_mes: this.cliente.fac_espa_pale2_mes,
            fac_espa_pale2_unidadesMes: this.cliente.fac_espa_pale2_unidadesMes,

          })

          this.iniciaPaleTipo1()
          this.iniciaPaleTipo2()

        }
      })
  }


  enviar() {
    this.enviado = true;

    if (this.cliForm.invalid) {
      console.log("ERRORES:", this.cliForm);
      return;
    }
    // Todo ok, enviar al BackEnd 

    const datos = {
      ...this.cliForm.value,
      id: this.cliente.id
    }
    this.loading = true;
    this.clienteSrv.actualizaCliFac(datos)
      .subscribe(resp => {
        this.loading = false;

        console.log('__actualizaCliFac()', resp)

        if (resp.error) {
          this.notificacionesService.aviso('error', resp.mensaje);
          console.log(resp.mensaje);
          for (let controlName in resp.mensaje) {
            this.cliForm.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
          }
        } else {
          this.notificacionesService.aviso('success', `Datos de facturación modificados`);
          this.router.navigateByUrl(`panel/clientes_lista`);
        }
      })
  }


  calcularSeguro(): void {
    const valor = parseFloat(this.cliForm.get('fac_seguro_valor')?.value);
    const porcentaje = parseFloat(this.cliForm.get('fac_seguro_porcentaje')?.value);

    if (!isNaN(valor) && !isNaN(porcentaje)) {
      const calculado = (valor * porcentaje) / 100;
      this.cliForm.get('fac_seguro_calculado')?.setValue(calculado.toFixed(2));
    } else {
      this.cliForm.get('fac_seguro_calculado')?.setValue(null);
    }
  }

  getTiposPale() {
    this.loading = true;
    this.ubiTiposSrv.getLista()
      .subscribe(respuesta => {
        this.loading = false;
        this.tiposPaleList = respuesta.data;
      })
  }

  ///   AUX   ///

  get f() {
    return this.cliForm.controls;
  }


  // Si se activa 'dia', desactivar 'fac_alma_finMes'
  fac_alma_diaChange(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    this.cliForm.get('fac_alma_finMes')?.setValue(!checkbox.checked);
    this.muestrafacDia = true;
  }

  // Si se activa 'mes', desactivar 'fac_alma_dia'
  fac_alma_finMesChange(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    this.cliForm.get('fac_alma_dia')?.setValue(!checkbox.checked);
    this.muestrafacDia = false;
  }

  fac_espa_pale1_tipoChange(selectedTipoPale: any): void {
    console.log('Tipo de pale seleccionado 1:', selectedTipoPale);
    this.paleTipo1 = selectedTipoPale;
    // si no hay nada seleccionado, borrar los precios de dia/mes para palé 1
    if (Object.keys(selectedTipoPale).length === 0) {
      this.cliForm.patchValue({
        fac_espa_pale1_dia: null,
        fac_espa_pale1_mes: null,
      });
    }
  }
  fac_espa_pale2_tipoChange(selectedTipoPale: any): void {
    console.log('Tipo de pale seleccionado 2:', selectedTipoPale);
    this.paleTipo2 = selectedTipoPale
    // si no hay nada seleccionado, borrar los precios de dia/mes para palé 2
    if (Object.keys(selectedTipoPale).length === 0) {
      this.cliForm.patchValue({
        fac_espa_pale2_dia: null,
        fac_espa_pale2_mes: null,
      });
    }
  }

  getSelectedTipoPale(event: Event): any {
    const selectElement = event.target as HTMLSelectElement | null;

    // Retorna un objeto vacío si selectElement es null o undefined
    if (!selectElement) return {};

    const selectedIndex = selectElement.selectedIndex - 1;
    if (selectedIndex < 0 || selectedIndex >= this.tiposPaleList.length) {
      return {}; // Retorna un objeto vacío si el índice seleccionado no es válido
    }

    return this.tiposPaleList[selectedIndex];
  }


  iniciaPaleTipo1(): Promise<void> {
    // Iniciar los valores de palé1
    return new Promise<void>((resolve, reject) => {
      this.getUbiTipo(this.f['fac_espa_pale1_tipo'].value)
        .then((ubiTipo1: IUbiTipo | undefined) => {
          if (ubiTipo1) {
            this.paleTipo1.nombre = ubiTipo1.nombre;
            resolve();
          }
        })
    });
  }

  iniciaPaleTipo2(): Promise<void> {
    // Iniciar los valores de palé2
    return new Promise<void>((resolve, reject) => {
      this.getUbiTipo(this.f['fac_espa_pale2_tipo'].value)
        .then((ubiTipo2: IUbiTipo | undefined) => {
          if (ubiTipo2) {
            this.paleTipo2.nombre = ubiTipo2.nombre;
            resolve();
          }
        })
    });
  }

  getUbiTipo(id: number): Promise<IUbiTipo | undefined> {
    return new Promise<IUbiTipo | undefined>((resolve, reject) => {
      this.loading = true;
      this.ubiTiposSrv.getUbiTipo(id).subscribe(resp => {
        this.loading = false;
        resolve(resp.data);
      });
    });
  }

}
