import * as Constantes from '../../../shared/constants';

// módulos
import { Component, ContentChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

// interfaces
import { IStock } from 'src/app/interfaces/stock';

// servicios
import { StockService } from 'src/app/services/datos/stock.service';
import { UsuarioService } from 'src/app/services/datos/usuario.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

// librerías
import FuncLib from 'src/app/providers/funcionesLib';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { tr } from 'date-fns/locale';


@Component({
  selector: 'app-stock-mover',
  templateUrl: './stock-mover.component.html',
  styleUrls: ['./stock-mover.component.scss']
})
export class StockMoverComponent implements OnInit {

  // Usar constantes en el template
  Constantes = Constantes

  public ubiForm: FormGroup = new FormGroup({});

  id_stock: string = '';
  public stock: IStock = {};

  stockList: IStock[] = [];
  stockDestinoList: IStock[] = [];
  public stockDestinoListErr: string = '';

  public ubiOrigen: string = '';
  id_ubiOrigen: number | null = null;

  public ubiDestino: string = '';

  public clienteOrigen: string = '';
  public clienteDestino: string = '';

  public enviado: boolean = false;
  public loading: boolean = false;

  private funcLib: FuncLib = new FuncLib();
  public codUbiOriDecript: string = '';
  public codUbiDesDecript: string = '';

  public sumas = {
    cantidad: 0,
    reservado: 0,
    bloqueado: 0,
    disponible: 0
  }


  constructor(
    private usuarioSrv: UsuarioService,
    private stockSrv: StockService,
    private fb: FormBuilder,
    private notificacionesService: NotificacionesService,
    private activatedRoute: ActivatedRoute,
    private router: Router,) { }


  ngOnInit(): void {
    this.getParametrosUrl();

    // Definir el form de ubicación
    this.ubiForm = this.fb.group({
      ubiDestino: ['', [
        Validators.required,
        Validators.minLength(12),
        Validators.maxLength(12)
      ]],
      facturable: [false],
    })

  }

  getParametrosUrl() {
    // Suscribirse a los parámetros de la url 
    this.activatedRoute.params.subscribe(({ id }) => {
      this.id_stock = id;
      this.getStock();
    })
  }

  enviar() {
    this.enviado = true;

    if (this.ubiForm.invalid) {
      console.log("ERRORES:", this.ubiForm);
      return;
    }

    if (this.clienteDestino && this.clienteDestino !== this.clienteOrigen) {
      this.ubiForm.get('ubiDestino')!.setErrors({ clienteNotMatch: true });
      return;
    }

    const factAux = this.ubiForm.get('facturable')?.value
    let facturable: string = '0';
    if (factAux) facturable = '1';

    const datos = {
      username: this.usuarioSrv.userdata.username,
      ubiOrigen: this.ubiOrigen,
      ubiDestino: this.ubiForm.get('ubiDestino')?.value,
      facturable: facturable
    };

    this.loading = true;
    this.stockSrv.stockMueve(datos).subscribe(resp => {
      this.loading = false;

      console.log(' __enviar datos', datos);
      console.log('__enviar resp', resp);

      if (resp.error) {
        console.log(resp.mensaje);
        for (let controlName in resp.mensaje) {
          this.ubiForm.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
        }
      } else {
        this.notificacionesService.aviso('success', `${resp.mensaje}`);
        this.router.navigate(['./panel/stock_lista']);
      }

    })

  }


  getStock() {
    this.loading = true;
    this.stockSrv.getRec(parseInt(this.id_stock))
      .subscribe(resp => {
        this.loading = false;

        if (resp.error) {
          this.notificacionesService.aviso('error', resp.mensaje);
        } else {
          this.stock = resp.data;
          this.ubiOrigen = this.stock.ubiCodigo!;
          this.id_ubiOrigen = resp.data.id_ubicacion!;

          this.cambiaUbiOri();
          this.getListaStock();
        }
      })
  }


  getListaStock() {
    // Lista del Stock en ORIGEN
    const opcionesList: any = {}
    opcionesList.id_ubicacion = this.id_ubiOrigen;
    this.loading = true;
    this.clienteOrigen = '';

    this.stockSrv.getLista(opcionesList)
      .subscribe(resp => {
        this.loading = false;

        if (resp.error) {
          this.notificacionesService.aviso('error', resp.mensaje);
        } else {
          this.stockList = resp.data;

          console.log('__getListaStock()', this.stockList);
          this.clienteOrigen = this.stockList[0].clienteNombre || '';

          // Sumar totales
          this.sumas = { cantidad: 0, reservado: 0, bloqueado: 0, disponible: 0 }

          this.stockList.forEach(item => {
            this.sumas.cantidad += Number(item.cantidad);
            this.sumas.reservado += Number(item.cantidadReservada);
            this.sumas.bloqueado += Number(item.bloqueado);
            this.sumas.disponible += Number(item.cantidadDisponible);
          });

          if (this.sumas.reservado) {
            Swal.fire({
              title: "Cantidades reservadas",
              html: `En esta ubicación, hay un total de <b>${this.sumas.reservado}</b> artículos reservados, estos NO se moveran`,
              icon: "info"
            });
          }

        }


      })
  }


  getListaStockDestino() {
    // Lista del Stock en DESTINO
    const opcionesList: any = {}
    opcionesList.codUbi = this.ubiDestino;
    this.loading = true;
    this.stockDestinoListErr = ''
    this.clienteDestino = ''

    this.stockSrv.getLista(opcionesList)
      .subscribe(resp => {
        this.loading = false;

        console.log('__getListaStockDestino() opcionesList', opcionesList);
        console.log('__getListaStockDestino() resp', resp);

        if (resp.error) {
          this.enviado = true;
          this.notificacionesService.aviso('error', resp.mensaje);
          this.stockDestinoListErr = resp.mensaje;
          this.ubiForm.get('ubiDestino')!.setErrors({ 'apiError': resp.mensaje }); 
        } else {
          this.stockDestinoList = resp.data;
          if (this.stockDestinoList && this.stockDestinoList.length > 0) {
            this.clienteDestino = this.stockDestinoList[0].clienteNombre || '';
          } else {
            this.clienteDestino = '';
          }
        }
      });
  }


  public cambiaUbiDes() {
    this.ubiDestino = this.ubiForm.get('ubiDestino')?.value;
    this.codUbiDesDecript = this.funcLib.CodUbiDecript(this.ubiDestino);

    if (this.ubiDestino.length === 12) {
      this.getListaStockDestino();
    } else {
      this.stockDestinoList = [];
      this.clienteDestino = ''
    }
  }

  public cambiaUbiOri() {
    this.codUbiOriDecript = this.funcLib.CodUbiDecript(this.ubiOrigen);
  }


  get f() {
    return this.ubiForm.controls;
  }

}
