import * as Constantes from '../../../shared/constants';

// Módulos
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

// Interfaces y Modelos  
import { IArtPreparacion } from 'src/app/interfaces/artPreparacion';
import { ICliente } from 'src/app/interfaces/cliente';
import { ICampanya } from 'src/app/interfaces/campanya';

// Servicios 
import { UsuarioService } from 'src/app/services/datos/usuario.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { PreparacionesService } from 'src/app/services/datos/preparaciones.service';
import { RepartosService } from 'src/app/services/datos/repartos.service';

// Librerías
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import * as XLSX from 'xlsx';
import { IApiResp } from 'src/app/interfaces/api-resp';


@Component({
  selector: 'app-asignar-pedidos',
  templateUrl: './asignar-pedidos.component.html',
  styleUrls: ['./asignar-pedidos.component.scss']
})
export class AsignarPedidosComponent implements OnInit, OnDestroy {

  //Usar constantes en el template
  Constantes = Constantes;

  id_campanya: string = '';

  public campForm: FormGroup = new FormGroup({});
  public enviado: boolean = false;
  public loading: boolean = false;
  loadingAlbaran: boolean = false;


  // Intervalo de refresco de la tabla
  refreshInterval: any;
  timeRefreshCampanyas: number = environment.timeRefreshCampanyas;

  public fotoDir: string = environment.fotoDir;

  public lista: IArtPreparacion[] = [];
  public userList: any[] = [];
  public campanyaInfo: ICampanya = {};
  public clienteInfo: ICliente = {};
  public pedidoCero: any = {};

  selected: any = [];
  public totSelCantidad: number = 0;
  public totSelUbicaciones: number = 0;
  public totSelUics: number = 0;

  private listaPedidos: any[] = [];

  SelectionType = SelectionType;
  selectAllRows: boolean = false;
  selectNoAssigned: boolean = false;

  public rows: any = [];
  public temp: any[] = [];

  public imgResized: string = localStorage.getItem('imgResized') || '/resize/';
  public summaryPosition: string = localStorage.getItem('summaryPosition') || 'bottom';
  public rowsLimitStr: string = localStorage.getItem('linTablas') || '10';
  public rowsLimit: number = parseInt(this.rowsLimitStr);

  alturasList: string[] = [];
  public altura: string = '*';

  pasillosList: string[] = [];
  public pasillo: string = '*';


  textoDestacado: string = '';

  messages = {
    emptyMessage: `<span class="text-danger">Sin datos...</span>`,
    totalMessage: `total`,
    selectedMessage: `seleccionados`
  }

  ColumnMode = ColumnMode;
  @ViewChild(AsignarPedidosComponent) table: AsignarPedidosComponent | any;

  constructor(
    private fb: FormBuilder,

    private prepSrv: PreparacionesService,
    private RepartoSrv: RepartosService,
    private usuarioSrv: UsuarioService,
    private notificacionesSrv: NotificacionesService,
    private activatedRoute: ActivatedRoute
  ) {
    // Definir formulario 
    this.campForm = this.fb.group({
      id_usuario: ['', Validators.required],
    })
  }



  ngOnInit(): void {
    // Suscribirse a los parámetros de la url, si envían una id, cargar los artículos de la campanya
    this.activatedRoute.params.subscribe(({ id }) => {
      this.id_campanya = id;
      this.cargaPreparacionesList();
    })

    // Configurar un intervalo para refrescar datos auomáticamente, solo se lanzará automáticamante si no se ha seleccionado ninguna preparación
    this.refreshInterval = setInterval(() => {
      this.refreshDataAuto();
    }, this.timeRefreshCampanyas); // tiempo para el Refesco automático de los datos de campañas

  }

  ngOnDestroy(): void {
    // Limpiar el intervalo cuando el componente se destruya
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }



  cargaPreparacionesList() {
    this.loading = true;
    this.lista = [];
    this.prepSrv.getPreparacionPorCampanyaAgrupado(Number(this.id_campanya))
      .subscribe(resp => {
        this.loading = false;

        console.log('__cargaPreparacionesList', resp);

        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
        } else {

          // Cargar solo los que cant_reserva > 0
          this.lista = resp.data.filter((item: any) => item.cant_reserva > 0);

          this.campanyaInfo = resp.totales.campanya;
          this.clienteInfo = resp.totales.cliente;
          this.pedidoCero = resp.totales.pedidos[0];

          // Añadir el estado de la preparación a la lista 
          this.lista.forEach((item: any) => {
            const cant_recogida = parseInt(item.cant_recogida);
            const cant_repartida = parseInt(item.cant_repartida);

            switch (true) {
              case cant_repartida > 0 && cant_repartida < cant_recogida:
                item.estado_preparacion = Constantes.ESTADO_PREPARACION_EMPEZADO;
                break;
              case cant_repartida === cant_recogida:
                item.estado_preparacion = Constantes.ESTADO_PREPARACION_COMPLETADA;
                break;
              default:
                item.estado_preparacion = Constantes.ESTADO_PREPARACION_PENDIENTE;
            }
          });

          console.log('__lista', this.lista);
        }

        // Generar el array listaPedidos con los id's de los pedidos de la campaña
        if (resp.totales) {
          this.listaPedidos = resp.totales.pedidos.map((pedido: any) => {
            return {
              id_pedido: pedido.id,
              cajetin: pedido.cajetin,
              articulo: {}
            };
          });
        }
        console.log('listaPedidos', this.listaPedidos);


        // Aviso de  campañas anteriores
        if (resp.totales && resp.totales.existeAnterior) {
          this.notificacionesSrv.aviso('warning', 'ATENCIÓN, Para este cliente existen campañas anteriores a esta en estado pendiente ó parcialmente asignadas');
        }

        this.rows = this.lista;
        this.temp = [...this.lista];

        this.cargaUsuariosList();

      })

  }


  // Refrescar los datos de la tabla
  refreshData() {
    this.cargaPreparacionesList()
    this.cancelarSelecctedClick() // Desmarcar checks de seleccion
  }

  // Refrescar los datos de la tabla automáticamente solo se ejecutará si NO se ha seleccionado ninguna preparación
  refreshDataAuto() {
    if (!this.selected.length) {
      this.refreshData()
    }
  }


  cargaUsuariosList() {
    this.userList = []
    const params = {
      id_almacen: this.clienteInfo.alm_por_defecto,
      rol: 'usuario'
    }


    this.usuarioSrv.getUsrListRol(params)
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
        } else {
          this.userList = resp.data;
        }

        console.log('__cargaUsuariosList', resp);
      })
  }




  enviar() {
    this.enviado = true;
    console.log(this.campForm);

    if (this.campForm.invalid) {
      console.log("ERRORES:", this.campForm);
      return;
    }

    const totalCantRepartida = this.selected.reduce((total: number, item: any) => {
      return total + (item.cant_repartida || 0);
    }, 0);

    console.log(`___Total cant_repartida: ${totalCantRepartida}`);

    if (totalCantRepartida > 0) {
      this.notificacionesSrv.aviso('warning', `No se puede cambiar la asignación, ya existen ${totalCantRepartida} artículos repartidos`);
      return;
    }

    if (!this.selected.length) {
      this.notificacionesSrv.aviso('warning', 'No se ha seleccionado ninguna preparación');
      return;
    }


    // Todo ok, enviar al BackEnd 
    const datos = {
      ...this.campForm.value,
      selected: this.selected,
      fecha_entrada: new Date(),
      campanyaInfo: this.campanyaInfo
    }

    this.loading = true;
    console.log("_datos=", datos);
    this.prepSrv.asignaUsrPreparacion(datos)
      .subscribe((resp: IApiResp) => {
        this.loading = false;

        console.log(resp);

        if (resp.error) {
          // this.notificacionesService.aviso('error', resp.mensaje);
          console.log(resp.mensaje);
          for (let controlName in resp.mensaje) {
            this.campForm.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
          }
        } else {
          this.notificacionesSrv.aviso('success', resp.mensaje);
          // this.router.navigateByUrl(`panel/campanyas_lista`); 
          this.selected = [];
          this.cargaPreparacionesList();
          return
        }
      })
  }


  cancelarSelecctedClick() {
    this.selectAllRows = false;
    this.selectNoAssigned = false;
    this.selected = [];
  }



  get f() {
    return this.campForm.controls;
  }


  //  **  AUX  ** 

  rowsLimitChange() {
    this.rowsLimit = parseInt(this.rowsLimitStr); // convertir el valor a número
  }


  buscarTodo(event: any) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    const temp = this.temp.filter((d: any) =>
      Object.keys(d).some((key: any) =>
        d[key] && d[key].toString().toLowerCase().includes(txt.toLowerCase())
      )
    );
    this.textoDestacado = txt;
    this.rows = temp;
    this.table = this.lista;
  }

  resaltarTexto(value: string | null, textoDestacado: string) {
    return value ? value.replace(new RegExp(textoDestacado, 'gi'), '<span class="resaltado">$&</span>') : '';
  }

  exportToExcel(): void {
    const worksheet = XLSX.utils.json_to_sheet(this.rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'datos.xlsx');
  }



  ///////////   AUX   /////////////


  pdfEtiquetaMulti() {
    Swal.fire({
      title: 'Imprimir etiquetas',
      icon: 'question',
      html: `¿Quieres imprimir las etiquetas para todas las cajas '1' de los pedidos de esta campaña?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('Imprimir etiquetas');

        const data = {
          lista: this.listaPedidos,
          id_usuario: this.usuarioSrv.userdata.id
        };

        console.log('__pdfEtiquetaMulti() data', data);
        this.loading = true;
        this.RepartoSrv.pdfEtiquetaMulti(data)
          .subscribe(resp => {
            this.loading = false;
            console.log('__pdfEtiquetaMulti()', resp);
            if (resp.error) {
              this.notificacionesSrv.aviso('error', resp.mensaje);
              return
            }
            this.notificacionesSrv.aviso('success', resp.mensaje);
          });

      }
    })
  }

  enviarPedidoClick() {
    // Envía pedido, es una campaña 1 a 1 de un solo pedido
    const totales = this.sumarCantidades();
    console.log('__totales=', totales);
    console.log('__campanyaInfo=', this.campanyaInfo);
    console.log('__this.lista[0].estado=', this.lista[0].estado);
    if (!totales.cantRecogida) {
      Swal.fire({
        title: "No puedes enviar el pedido!",
        text: `Se ha de recoger algún artículo para poder hacer el envío`,
        icon: "error"
      });
      return;
    }

    let titulo: string = '¿Enviar pedido?';
    let icono: SweetAlertIcon = 'question';
    let html: string = 'Se generará el albarán y la orden de envío para este pedido';

    if (totales.cantRecogida !== totales.cantReserva) {
      titulo = "No está todo recogido!";
      icono = "warning" as SweetAlertIcon;
      html = `Solo se han recogido ${totales.cantRecogida} de ${totales.cantReserva} artículos.<br>¿Quieres servir el pedido con faltas?`;

    }

    Swal.fire({
      title: titulo,
      icon: icono,
      html: html,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {

        const data = {
          pedido: this.listaPedidos[0],
          id_usuario: this.usuarioSrv.userdata.id,
          clienteInfo: this.clienteInfo
        };

        console.log('__enviarPedidoClick() data', data);

        this.loading = true;
        this.loadingAlbaran = true;
        this.RepartoSrv.pedidoSaleUnoaUno(data)
          .subscribe(resp => {
            this.loading = false;
            this.loadingAlbaran = false;
            console.log('pedidoSaleUnoaUno()', resp);
            if (resp.error) {
              this.notificacionesSrv.aviso('error', resp.mensaje);
              return
            }
            this.notificacionesSrv.aviso('success', resp.mensaje);
            this.cargaPreparacionesList();
          });

      }
    })
  }






  public sumaCeldas(cells: any[]) {
    // Devuelve la suma de la columna, formateada y sin decimales
    const filteredCells = cells.filter(cell => !!cell);
    const sum: number = filteredCells.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0);
    // return sum.toLocaleString('es');
    return sum.toLocaleString('de-DE');
  }

  public cuentaCeldas(cells: any[]) {
    // Devuelve el número de filas
    const filteredCells = cells.filter(cell => !!cell);
    const sum: number = filteredCells.reduce((accumulator, currentValue) => accumulator + 1, 0);
    return sum.toLocaleString('de-DE');
  }



  // ** Selección de líneas  ** //

  onActivate(event: any) {

    // Solo para tipo de campañas normales, las de tipo pedido 1 a 1 no se selccionan
    if (this.campanyaInfo.campanyaTipo === '1 a 1') {
      console.log('DENTRO', this.campanyaInfo.campanyaTipo);
      this.selected = [];
      return;
    }

    // Evitar selección en caso de doble click
    if (event.type === 'dblclick') {
      event.rowElement.classList.remove('datatable-row-selected'); // Desmarca la fila

      this.filtraEmpezados()

      // Se está seleccionando/deseleccionado la fila   
      let estadoSelecRow = this.selected.includes(event.row); // Saber si estamos seleccionando / Desseleccionado
      if (estadoSelecRow) this.selectIgualesUic(event.row.uic);
      if (!estadoSelecRow) this.noSelectIgualesUic(event.row.uic);

      this.totalesSelected();
      return;
    }


    if (event.type === 'click') {
      this.selectAllRows = false;

      this.filtraEmpezados()

      // Se está seleccionando/deseleccionado la fila    
      let estadoSelecRow = this.selected.includes(event.row); // Saber si estamos seleccionando / Desseleccionado
      if (estadoSelecRow) this.selectIgualesUic(event.row.uic);
      if (!estadoSelecRow) this.noSelectIgualesUic(event.row.uic);

      this.totalesSelected();

      console.log('_selected ', this.selected);
    }
  }

  selectIgualesUic(selectedUic: string) {
    // Añade elementos con el mismo uic a this.selected
    if (!this.selected || !selectedUic) return;

    const itemsWithSameUic = this.lista.filter((item: { uic?: string }) => item.uic === selectedUic);
    const nuevosItems = itemsWithSameUic.filter(item => !this.selected.includes(item));

    this.selected = [...this.selected, ...nuevosItems];
  }

  noSelectIgualesUic(selectedUic: string) {
    // Excluye elementos con el mismo uic en this.selected
    if (!this.selected || !selectedUic) return;
    this.selected = this.selected.filter((item: { uic: string }) => item.uic !== selectedUic);
  }

  toggleSelectAllRows() {
    // Establecer todos los elementos como seleccionados?
    const allRows = [...this.rows];

    if (this.selectAllRows) this.selected = [...allRows];
    if (!this.selectAllRows) this.selected = [];

    this.filtraEmpezados();
    this.totalesSelected();
  }



  toggleNoAssigned() {

    console.log('__this.rows', this.rows);


    // Filtrar las filas donde usr_uic es NULL
    const noAssignedRows = this.rows.filter((r: { usr_uic: null; }) => r.usr_uic === null);

    if (this.selectNoAssigned) {
      // Si está seleccionando, agrega las filas no asignadas a las seleccionadas
      this.selected = [...this.selected, ...noAssignedRows];
    } else {
      // Si no está seleccionando, filtra las filas no asignadas de las seleccionadas
      this.selected = this.selected.filter((r: any) => !noAssignedRows.includes(r));
    }

    this.filtraEmpezados();
    this.totalesSelected();
  }


  filtraEmpezados() {
    // Solo seleccionar los carros con recogida acabada

    this.selected = this.selected.filter(
      (item: { estado: number }) =>
        item.estado == 3
    );

    // No seleccionar los ya empezados y con item.uic no nulo

    this.selected = this.selected.filter(
      (item: { estado_preparacion: number, cant_repartida: number, uic: string, usr_uic: string }) =>
        item.estado_preparacion == 1
        && item.uic != null
        && item.cant_repartida < 1
      // && item.usr_uic == null
    );


  }



  totalesSelected() {
    // Calcula los totales sobre los seleccionados
    this.totSelCantidad = this.selected.reduce((total: number, obj: { cant_reserva: string; }) => total + parseInt(obj.cant_reserva), 0);
    this.totSelUbicaciones = this.selected.reduce((total: number, obj: { totUbicaciones: string; }) => total + parseInt(obj.totUbicaciones), 0);

    // Crear un Set para eliminar duplicados de UIC
    const uicUnicos = new Set(this.selected.map((obj: { uic: string; }) => obj.uic));
    this.totSelUics = uicUnicos.size
  }


  limitarYAgregarPuntos(value: string, maxLength: number): string {
    // Acorta un string a maxLength caracteres
    if (value.length > maxLength) {
      return value.substring(0, maxLength) + '...';
    } else {
      return value;
    }
  }


  sumarCantidades(): { cantRecogida: number, cantReserva: number } {
    let cantRecogidaTotal = 0;
    let cantReservaTotal = 0;

    for (const elemento of this.lista) {
      // Convertir las cantidades a números antes de sumar
      cantRecogidaTotal += +elemento.cant_recogida!;
      cantReservaTotal += +elemento.cant_reserva!;
    }

    return { cantRecogida: cantRecogidaTotal, cantReserva: cantReservaTotal };
  }


}
