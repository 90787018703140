<div *ngIf="loading"
     class="loading-overlay">
    <div class="spinner"></div>
    <h3 class="mt-3 ml-1 text-info ">Generando datos de facturación...</h3>
</div>


<!-- Entradas -->
<span *ngIf="datosFacturacion.length">
    <div class="card border border-info rounded">
        <div class="card-body">

            <div class="d-flex justify-content-between align-items-center">
                <h2>Entradas</h2>
                <button class="btn btn-seccondary mb-2"
                        [disabled]="loading"
                        (click)="abrirPdfClick()">
                    <i class="fa fa-spinner fa-spin"
                       *ngIf="loading"></i> Abrir detalle</button>
            </div>


            <!-- RESUMEN -->

            <table class="table table-sm table-striped table-bordered table-hover">

                <thead>
                    <tr>
                        <th class="text-info"
                            style="text-align: right;">Totales de {{ totales.entradas.porEntrada.Cantidad | number: '0.0':'es'}} Albaranes ubicados</th>
                        <th style="text-align: right;">Cantidad </th>
                        <th style="text-align: right;">Precio </th>
                        <th style="text-align: right;">Importe </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="text-align: right;">Líneas Mínimo</td>
                        <td style="text-align: right;">{{ totales.entradas.lineasMinimo.Cantidad | number: '0.0':'es'}}</td>
                        <td style="text-align: right;">{{ totales.entradas.lineasMinimo.Precio | euroCurrency }} </td>
                        <td style="text-align: right;">{{ totales.entradas.lineasMinimo.Importe | euroCurrency }} </td>
                    </tr>

                    <tr>
                        <td style="text-align: right;">Líneas Adicionales</td>
                        <td style="text-align: right;">{{ totales.entradas.lineasAdicionales.Cantidad | number: '0.0':'es' }}</td>
                        <td style="text-align: right;">{{ totales.entradas.lineasAdicionales.Precio | euroCurrency }}</td>
                        <td style="text-align: right;">{{ totales.entradas.lineasAdicionales.Importe | euroCurrency}}</td>
                    </tr>

                    <tr>
                        <td style="text-align: right;">Por unidad</td>
                        <td style="text-align: right;">{{ totales.entradas.unidades.Cantidad | number: '0.0':'es' }}</td>
                        <td style="text-align: right;">{{ totales.entradas.unidades.Precio | euroCurrency }}</td>
                        <td style="text-align: right;">{{ totales.entradas.unidades.Importe | euroCurrency}}</td>
                    </tr>

                    <tr>
                        <td style="text-align: right;">Por descarga de palé</td>
                        <td style="text-align: right;">{{ totales.entradas.pales.Cantidad | number: '0.0':'es' }}</td>
                        <td style="text-align: right;">{{ totales.entradas.pales.Precio | euroCurrency }}</td>
                        <td style="text-align: right;">{{ totales.entradas.pales.Importe | euroCurrency}}</td>
                    </tr>

                    <tr>
                        <td style="text-align: right;">Líneas que gestionan lote</td>
                        <td style="text-align: right;">{{ totales.entradas.lineasConLote.Cantidad | number: '0.0':'es' }}</td>
                        <td style="text-align: right;">{{ totales.entradas.lineasConLote.Precio | euroCurrency }}</td>
                        <td style="text-align: right;">{{ totales.entradas.lineasConLote.Importe | euroCurrency}}</td>
                    </tr>

                    <tr>
                        <td style="text-align: right;">Horas empleadas</td>
                        <td style="text-align: right;">{{ totales.entradas.horas.Cantidad | number: '0.0':'es' }}</td>
                        <td style="text-align: right;">{{ totales.entradas.horas.Precio | euroCurrency }}</td>
                        <td style="text-align: right;">{{ totales.entradas.horas.Importe | euroCurrency}}</td>
                    </tr>

                    <tr>
                        <td style="text-align: right;">Etiquetas</td>
                        <td style="text-align: right;">{{ totales.entradas.etiquetado.Cantidad | number: '0.0':'es' }}</td>
                        <td style="text-align: right;">{{ totales.entradas.etiquetado.Precio | euroCurrency }}</td>
                        <td style="text-align: right;">{{ totales.entradas.etiquetado.Importe | euroCurrency}}</td>
                    </tr>

                    <tr>
                        <td style="text-align: right;">Contenedores de 20 pies</td>
                        <td style="text-align: right;">{{ totales.entradas.container20.Cantidad | number: '0.0':'es' }}</td>
                        <td style="text-align: right;">{{ totales.entradas.container20.Precio | euroCurrency }}</td>
                        <td style="text-align: right;">{{ totales.entradas.container20.Importe | euroCurrency}}</td>
                    </tr>

                    <tr>
                        <td style="text-align: right;">Contenedores de 40 pies</td>
                        <td style="text-align: right;">{{ totales.entradas.container40.Cantidad | number: '0.0':'es' }}</td>
                        <td style="text-align: right;">{{ totales.entradas.container40.Precio | euroCurrency }}</td>
                        <td style="text-align: right;">{{ totales.entradas.container40.Importe | euroCurrency}}</td>
                    </tr>

                    <tr>
                        <td style="text-align: right;">Por entrada</td>
                        <td style="text-align: right;">{{ totales.entradas.porEntrada.Cantidad | number: '0.0':'es' }}</td>
                        <td style="text-align: right;">{{ totales.entradas.porEntrada.Precio | euroCurrency }}</td>
                        <td style="text-align: right;">{{ totales.entradas.porEntrada.Importe | euroCurrency}}</td>
                    </tr>
                </tbody>

                <tfoot>
                    <tr class="total-row">
                        <td style="text-align: right;"><strong>Total</strong></td>
                        <td></td>
                        <td></td>
                        <td style="text-align: right;"><strong>{{ totales.entradas._sumas.Importe | euroCurrency }}</strong></td>
                    </tr>
                </tfoot>
            </table>

        </div>
    </div>
</span>


<!-- Sin datos -->
<span *ngIf="sinDatos">
    <div class="card border border-info rounded">
        <div class="card-body">
            <h2>ENTRADAS</h2>
            <h3 class="text-success"> Sin Datos</h3>
        </div>
    </div>
</span>