// Componentes
import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable'

//Modelos e Interfaces
import { ICliente } from 'src/app/interfaces/cliente';

// Servicios
import { ClientesService } from 'src/app/services/datos/clientes.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';
import { ModalImagenService } from 'src/app/services/modal-imagen.service'

// Entrorno y Librerias
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';


interface Editing {
  [key: string]: boolean;
}

@Component({
  selector: 'app-clientes-lista',
  templateUrl: './clientes-lista.component.html',
  styleUrls: ['./clientes-lista.component.scss']
})
export class ClientesListaComponent implements OnInit {
  public fotoDir: string = environment.fotoDir;
  loading: boolean = false;

  textoDestacado: string = '';

  messages = {
    emptyMessage: `<span class="text-danger">Sin datos...</span>`,
    totalMessage: 'Total',
    selectedMessage: 'seleccionado'
  }


  clientesList: ICliente[] = [];
  rows: any[] = [];
  temp: any[] = [];

  columns = [];
  ColumnMode = ColumnMode;

  editing: Editing = {};

  public rowsLimitStr: string = localStorage.getItem('linTablas') || '10';
  public rowsLimit: number = parseInt(this.rowsLimitStr);

  @ViewChild(ClientesListaComponent) table: ClientesListaComponent | any;

  constructor(
    private router: Router,
    public clienteSrv: ClientesService,
    private notificacionesSrv: NotificacionesService,
    private modalImagenSrv: ModalImagenService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.clienteSrv.getLista()
      .subscribe(resp => {
        this.loading = false;
        this.clientesList = resp.data;
        this.rows = this.clientesList;
        this.temp = [...this.clientesList];

        console.log('__clientesList', this.clientesList);
      })
  }


  filtro(val: string, campo: string) {

    // filter our data
    const temp = this.temp.filter(function (d) {
      if (d[campo]) return d[campo].toLowerCase().indexOf(val) !== -1 || !val;
      return !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table = this.clientesList;
  }


  buscar(event: any, campo: string) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    this.filtro(txt, campo);
  }


  buscarTodo(event: any) {
    const txt = event?.target?.value?.toLowerCase() ?? '';
    const temp = this.temp.filter((d: any) =>
      Object.keys(d).some((key: any) =>
        d[key] && d[key].toString().toLowerCase().includes(txt.toLowerCase())
      )
    );

    // Marcar el texto destacado
    this.textoDestacado = txt;

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table = this.clientesList;
  }

  resaltarTexto(value: string, textoDestacado: string) {
    if (!textoDestacado) {
      return value;
    }
    return value.replace(new RegExp(textoDestacado, 'gi'), '<span class="resaltado">$&</span>');
  }


  rowsLimitChange() {
    this.rowsLimit = parseInt(this.rowsLimitStr); // convertir el valor a número
  }

  //  CRUD  // 

  deleteRow(row: any) {
    Swal.fire({
      title: '¿Borrar cliente?',
      icon: 'question',
      html: `Eliminar <b>${row.nombre}</b>`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si',
    }).then((result) => {
      if (result.isConfirmed) {
        this.clienteSrv.deleteCli(row.id)
          .subscribe(resp => {
            if (resp.error) {
              this.notificacionesSrv.aviso('error', resp.mensaje);
            } else {
              this.notificacionesSrv.aviso('info', `Registro eliminado`);
              this.rows = this.rows.filter((objeto) => objeto.id !== row.id);
            }
          })
      }
    })


  }


  updateValue(event: any, cell: string, rowIndex: number) {
    console.log('inline editing rowIndex', rowIndex);

    this.editing[rowIndex + '-' + cell] = false;

    const newValue = event.target.value;
    const oldValue = this.rows[rowIndex][cell];

    this.rows[rowIndex][cell] = newValue;
    this.rows = [...this.rows];

    this.clienteSrv.actualizaCli(this.rows[rowIndex])
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.rows[rowIndex][cell] = oldValue;
          this.notificacionesSrv.aviso('error', resp.mensaje);
        } else {
          this.notificacionesSrv.aviso('success', `Datos del cliente actualizados`);
        }

      })

  }


  ///   AUX   ///

  exportToExcel(): void {
    const worksheet = XLSX.utils.json_to_sheet(this.rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, 'datos.xlsx');
  }

  openIntranetCliente() {
    window.open(environment.url, '_blank');
  }
  

}
