<div *ngIf="loading"
     class="loading-overlay">
    <div class="spinner"></div>
    <h3 class="mt-3 ml-1 text-info ">Generando datos de facturación...</h3>
</div>


<!-- Almacenaje -->
<span *ngIf="!isObjectEmpty(totales) && !sinDatos">
    <div class="card border border-info rounded">
        <div class="card-body">

            <div class="d-flex justify-content-between align-items-center">
                <h2>Almacenaje</h2>
                <button class="btn btn-seccondary mb-2"
                        [disabled]="loading"
                        (click)="abrirPdfClick()">
                    <i class="fa fa-spinner fa-spin"
                       *ngIf="loading"></i> Abrir detalle</button>
            </div>


            <!-- RESUMEN -->

            <table class="table table-sm table-striped table-bordered table-hover">

                <thead>
                    <tr>
                        <th class="text-info"
                            style="text-align: right;">Total en {{ totales.dias | number: '0.0':'es'}} días de almacenaje</th>
                        <th style="text-align: right;">Unidades de facturación</th>
                        <th style="text-align: right;">Precio/día </th>
                        <th style="text-align: right;">Importe </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="text-align: right;">Cajetines</td>
                        <td style="text-align: right;">{{ totales.almacenaje.cajetines.Cantidad | number: '0.0':'es'}}</td>
                        <td style="text-align: right;">{{ totales.almacenaje.cajetines.Precio | euroCurrency }} </td>
                        <td style="text-align: right;">{{ totales.almacenaje.cajetines.Importe | euroCurrency }} </td>
                    </tr>
                    <tr *ngIf="totales.pale01 && totales.pale01.nombre">
                        <td style="text-align: right;">{{totales.pale01.nombre}}</td>
                        <td style="text-align: right;">{{ totales.almacenaje.pale01.Cantidad | number: '0.0':'es'}}</td>
                        <td style="text-align: right;">{{ totales.almacenaje.pale01.Precio | euroCurrency }} </td>
                        <td style="text-align: right;">{{ totales.almacenaje.pale01.Importe | euroCurrency }} </td>
                    </tr>
                    <tr *ngIf="totales.pale02 && totales.pale02.nombre">
                        <td style="text-align: right;">{{totales.pale02.nombre}}</td>
                        <td style="text-align: right;">{{ totales.almacenaje.pale02.Cantidad | number: '0.0':'es'}}</td>
                        <td style="text-align: right;">{{ totales.almacenaje.pale02.Precio | euroCurrency }} </td>
                        <td style="text-align: right;">{{ totales.almacenaje.pale02.Importe | euroCurrency }} </td>
                    </tr>
                </tbody>

                <thead>
                    <tr>
                        <th class="text-info"
                            style="text-align: right;"></th>
                        <th style="text-align: right;">Cantidad</th>
                        <th style="text-align: right;">Precio</th>
                        <th style="text-align: right;">Importe</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="text-align: right;">Movimientos de palés</td>
                        <td style="text-align: right;">{{ totales.almacenaje.transacPales.Cantidad | number: '0.0':'es'}}</td>
                        <td style="text-align: right;">{{ totales.almacenaje.transacPales.Precio | euroCurrency }} </td>
                        <td style="text-align: right;">{{ totales.almacenaje.transacPales.Importe | euroCurrency }} </td>
                    </tr>
                    <tr>
                        <td style="text-align: right;">Movimientos de referencias</td>
                        <td style="text-align: right;">{{ totales.almacenaje.transacRefer.Cantidad | number: '0.0':'es'}}</td>
                        <td style="text-align: right;">{{ totales.almacenaje.transacRefer.Precio | euroCurrency }} </td>
                        <td style="text-align: right;">{{ totales.almacenaje.transacRefer.Importe | euroCurrency }} </td>
                       </tr>
                </tbody>


                <tfoot>
                    <tr class="total-row">
                        <td style="text-align: right;"><strong>Total</strong></td>
                        <td></td>
                        <td></td>
                        <td style="text-align: right;"><strong>{{ totales.almacenaje._sumas.Importe | euroCurrency }}</strong></td>
                    </tr>
                </tfoot>
            </table>

        </div>
    </div>
</span>


<!-- Sin datos -->
<span *ngIf="sinDatos">
    <div class="card border border-info rounded">
        <div class="card-body">
            <h2>ALMACENAJE</h2>
            <h3 class="text-success"> Sin Datos</h3>
        </div>
    </div>
</span>