<div [class.oculto]="modalFactEntradaSrv.ocultarModal"
     class="fondo-modal-imagen ">

    <div class="modal fade show"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel1"
         style="display: block;"
         (click)="cerrarModalClickOutside($event)">

        <div class="modal-dialog modal-lg"
             role="document">

            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title"
                        id="exampleModalLabel1">
                        <i class="fa-solid fa-truck-arrow-right"></i>
                        Entrada de {{modalFactEntradaSrv.entrada?.cliNombre }}
                        en {{modalFactEntradaSrv.entrada?.almNombre }}
                        el {{modalFactEntradaSrv.entrada?.created_at | date:'dd/MM/yyyy HH:mm'}}
                        <span *ngIf="modalFactEntradaSrv.entrada?.devolucion == '1'"
                              class="text-danger"> - Devolución</span>
                    </h4>
                    <button type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close">
                        <span (click)="cerrarModal()"
                              aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">

                    <div class="card card-body">

                        <form [formGroup]="factForm"
                              (ngSubmit)="enviar()"
                              class="form-horizontal">

                            <div class="line-with-text">
                                <h6>ALBARAN: <b>{{modalFactEntradaSrv.entrada?.albaran}}</b> - Modificar datos de facturación</h6>
                                <hr>
                            </div>

                            <div class="row">

                                <div class="col-md-4">
                                    <div *ngIf="modalFactEntradaSrv.entrada?.devolucion =='0'"
                                         class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['contenedor'].errors }">
                                        <label class="form-control-label">Contenedor descargado:</label>
                                        <select formControlName="contenedor"
                                                class="form-control form-control-sm"
                                                [ngClass]="{ 'form-control-danger': enviado && f['contenedor'].errors }">
                                            <option value="0">Ninguno</option>
                                            <option value="20 pies">20 pies</option>
                                            <option value="40 pies">40 pies</option>
                                        </select>
                                        <div *ngIf="enviado && f['contenedor'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="f['contenedor'].errors['required']">Obligatorio</div>
                                            <div *ngIf="factForm.get('contenedor')?.errors?.['apiError']">{{
                                                factForm.get('contenedor')?.errors?.['apiError'] }}</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row">

                                <!-- fac_pales -->
                                <div class="col-md-4">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['fac_pales'].errors }">
                                        <label class="form-control-label">Palés descargados:</label>
                                        <input type="text"
                                               step='1'
                                               formControlName="fac_pales"
                                               class="form-control form-control-sm"
                                               mask="separator.0"
                                               suffix=" Palés"
                                               [ngClass]="{ 'form-control-danger': enviado && f['fac_pales'].errors }">

                                        <div *ngIf="enviado && f['fac_pales'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="factForm.get('fac_pales')?.errors?.['apiError']">
                                                {{factForm.get('fac_pales')?.errors?.['apiError'] }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!-- fac_horas -->
                                <div class="col-md-4">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['fac_horas'].errors }">
                                        <label class="form-control-label">Horas:</label>
                                        <input type="text"
                                               step='1'
                                               formControlName="fac_horas"
                                               class="form-control form-control-sm"
                                               mask="separator.2"
                                               suffix=" horas"
                                               [ngClass]="{ 'form-control-danger': enviado && f['fac_horas'].errors }">

                                        <div *ngIf="enviado && f['fac_horas'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="factForm.get('fac_horas')?.errors?.['apiError']">
                                                {{factForm.get('fac_horas')?.errors?.['apiError'] }}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <!-- fac_etiquetas -->
                                <div class="col-md-4">
                                    <div class="form-group"
                                         [ngClass]="{ 'has-danger': enviado && f['fac_etiquetas'].errors }">
                                        <label class="form-control-label">Etiquetas creadas:</label>
                                        <input type="text"
                                               step='1'
                                               formControlName="fac_etiquetas"
                                               class="form-control form-control-sm"
                                               mask="separator.0"
                                               suffix=" Etiquetas"
                                               [ngClass]="{ 'form-control-danger': enviado && f['fac_etiquetas'].errors }">

                                        <div *ngIf="enviado && f['fac_etiquetas'].errors"
                                             class="form-control-feedback">
                                            <div *ngIf="factForm.get('fac_etiquetas')?.errors?.['apiError']">
                                                {{factForm.get('fac_etiquetas')?.errors?.['apiError'] }}
                                            </div>
                                        </div>
                                    </div>
                                </div>




                            </div> <!-- row -->



                            <hr>

                            <div class="d-flex justify-content-between">

                                <button [disabled]="loading"
                                        type="button"
                                        (click)="cerrarModal()"
                                        class="btn waves-effect waves-light btn-rounded btn-secondary"
                                        data-dismiss="modal">Cancelar</button>

                                <button [disabled]="loading"
                                        type="submit"
                                        class="ml-2 btn waves-effect waves-light btn-rounded btn-success">
                                    <i class="fa fa-spinner fa-spin"
                                       *ngIf="loading"></i> Enviar</button>
                            </div>

                        </form>
                    </div>


                </div>
            </div>
        </div>
    </div>


</div>