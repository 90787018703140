import { Pipe, PipeTransform } from '@angular/core';

// Imprime packing List
// Tabla: clientes-> campo: prn_packing_list
@Pipe({
  name: 'imprimePackingList'
})
export class ImprimePackingListPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    //   Si,   No

    switch (value) {
      case 'S':
        return '<span class="text-success">Si</span>';
      case 'N':
        return '<span class="text-warning">No</span>';
      default:
        return `<span class="text-muted">${value}</span>`;
    }

  }

}
