<div *ngIf="loading"
     class="loading-overlay">
    <div class="spinner"></div>
    <h3 class="mt-3 ml-1 text-info ">Cargando...</h3>
</div>


<div class="row ">

    <div class="col-12">
        <div class="card border rounded animated fadeIn fast">
            <div class="card-body animated fadeIn fast">

                <!-- cabecera -->
                <div class="row mb-2">

                    <div class="col-3">
                        <div class="input-group border border-dark rounded">
                            <input class="form-control"
                                   type="text"
                                   id="buscaTodo"
                                   autofocus
                                   placeholder="Buscar cualquier dato..."
                                   [(ngModel)]="strBuscarTodo"
                                   (keyup)="buscarTodo($event)" />
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary"
                                        type="button"
                                        title="Limpiar búsqueda"
                                        (click)="limpiaBusca()">
                                    <i class="fas fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="col-2">
                        <div class=" input-group border border-dark rounded">
                            <div class="input-group-addon">Fecha</div>
                            <input class="form-control"
                                   type="date"
                                   [(ngModel)]="fechaStock"
                                   (ngModelChange)="onFechaChange($event)">
                        </div>
                    </div>


                    <div class="col-2">
                        <div class=" input-group border border-dark rounded">
                            <div class="input-group-addon"><i class="fa-solid fa-warehouse"></i></div>
                            <select class="form-control "
                                    [(ngModel)]="almSeleccionado"
                                    (ngModelChange)="onAlmacenSelect($event)">
                                <option value=0>Todos los almacenes</option>
                                <option *ngFor="let alm of almacenesList"
                                        [value]=alm.id>
                                    {{alm.nombre}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-2">
                        <div class="input-group border border-dark rounded">
                            <div class="input-group-addon"><i class="fa-solid fa-user"></i></div>
                            <select class="form-control"
                                    [(ngModel)]="cliSeleccionado"
                                    (ngModelChange)="onClienteSelect($event)">
                                <option value=''>Todos los clientes</option>
                                <option *ngFor="let cli of clientesList"
                                        [value]=cli.id>
                                    {{cli.nombre}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-3">
                        <form>

                            <div class="row float-right">
                                <div class="col-sm-8">
                                    <div class="form-group rounded">
                                        <select class="form-control"
                                                name="rowsLimitStr"
                                                [(ngModel)]="rowsLimitStr"
                                                (change)="rowsLimitChange()">
                                            <option [value]="10">10</option>
                                            <option [value]="50">50</option>
                                            <option [value]="100">100</option>
                                            <option [value]="999999">Todo</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group float-right">
                                        <div class="btn-group">
                                            <!-- 
                                                <button type="button"
                                                    [routerLink]="'/panel/stock_edit/new'"
                                                    class="btn btn-outline-info btn-circle"
                                                    data-toggle="tooltip"
                                                    title="Nuevo">
                                                <i class="fa-solid fa-plus"></i>
                                            </button> 
                                        -->
                                            <button type="button"
                                                    (click)="exportToExcel()"
                                                    class="btn btn-outline-info btn-circle"
                                                    data-toggle="tooltip"
                                                    title="Descargar Excel">
                                                <i class="fa-solid fa-download"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </form>
                    </div>

                </div>



                <div class="rounded border">
                    <!-- ngx-datatable -->
                    <ngx-datatable class='bootstrap'
                                   [columnMode]="ColumnMode.flex"
                                   [loadingIndicator]="loading"
                                   [headerHeight]="70"
                                   [footerHeight]="50"
                                   [rowHeight]="50"
                                   [limit]="rowsLimit"
                                   [rows]='rows'
                                   [messages]="messages"
                                   [footerHeight]="50"
                                   [summaryRow]="true"
                                   [summaryHeight]="55"
                                   [summaryPosition]="summaryPosition">


                        <!-- id -->
                        <ngx-datatable-column name="id"
                                              [flexGrow]="10">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'id')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link">id</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div (dblclick)="dblclickCampo(value)"
                                     title="Doble click para busqueda global"
                                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- id_articulo -->
                        <ngx-datatable-column name="id Art."
                                              prop='id_articulo'
                                              [flexGrow]="10">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'id_articulo')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link">id Art.</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div (dblclick)="dblclickCampo(value)"
                                     title="Doble click para busqueda global"
                                     title="{{row.clienteNombre}}">
                                    <span [innerHTML]="resaltarTexto(value, textoDestacado)"></span>
                                </div>

                            </ng-template>
                        </ngx-datatable-column>



                        <!-- articulo_sku -->
                        <ngx-datatable-column name="articuloSku"
                                              [flexGrow]="20">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'articuloSku')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link">SKU</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div (dblclick)="dblclickCampo(value)"
                                     title="Doble click para busqueda global">
                                    <span [innerHTML]="resaltarTexto(value, textoDestacado)"></span>
                                    <span *ngIf="row.articuloSwDefectuoso == Const.DEFECTUOSO">
                                        <i title="Defectuoso"
                                           class="text-warning ml-1 fa-solid fa-triangle-exclamation"></i>
                                    </span>
                                </div>

                            </ng-template>
                        </ngx-datatable-column>



                        <!-- articuloEan -->
                        <ngx-datatable-column name="articuloEan"
                                              [flexGrow]="20">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'articuloEan')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer  link">Ean</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div (dblclick)="dblclickCampo(value)"
                                     title="Doble click para busqueda global"
                                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>





                        <!-- descripcion -->
                        <ngx-datatable-column name="descripcion"
                                              [flexGrow]="35">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'descripcion')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer  link">Descripcion</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div (dblclick)="dblclickCampo(value)"
                                     title="Doble click para busqueda global"
                                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>





                        <!-- lote -->
                        <ngx-datatable-column name="lote"
                                              [flexGrow]="10">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'lote')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link">Lote</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div (dblclick)="dblclickCampo(value)"
                                     title="Doble click para busqueda global"
                                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>




                        <!-- ubiCodigo -->
                        <ngx-datatable-column name="ubiCodigo"
                                              pre="Ubicación"
                                              [flexGrow]="18">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'ubiCodigo')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link">Ubicación</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div (dblclick)="dblclickCampo(value)"
                                     title="Doble click para busqueda global"
                                     class="monospace"
                                     [innerHTML]="resaltarTexto(value | mask: '00.00.00.00.00.00', textoDestacado | mask: '00.00.00.00.00.00')"></div>
                            </ng-template>
                        </ngx-datatable-column>




                        <!-- cantidad -->
                        <ngx-datatable-column name="cantidad"
                                              [flexGrow]="10"
                                              [cellClass]="'text-right'"
                                              [summaryFunc]="sumaCeldas">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'cantidad')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link"
                                        title="Cantidad de artículos en esta ubicación">Stock</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                {{ value | number: '0.0-0':'es'}}
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- cantidadReservada -->
                        <ngx-datatable-column name="cantidadReservada"
                                              [flexGrow]="10"
                                              [cellClass]="'text-right'"
                                              [summaryFunc]="sumaCeldas">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'cantidadReservada')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link"
                                        title="Cantidad reservada">Res.</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>{{ value | number: '0.0-0':'es' }}</ng-template>
                        </ngx-datatable-column>


                        <!-- bloqueado -->
                        <ngx-datatable-column name="bloqueado"
                                              [flexGrow]="10"
                                              [cellClass]="'text-right'"
                                              [summaryFunc]="sumaCeldas">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'bloqueado')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link"
                                        title="Cantidad bloqueada">Bloq.</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>{{ value | number: '0.0-0':'es' }}</ng-template>
                        </ngx-datatable-column>



                        <!-- fechaEntrada -->
                        <ngx-datatable-column name="fechaEntrada"
                                              [flexGrow]="12">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'fechaEntrada')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link">F.entrada</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div (dblclick)="dblclickCampo(value)"
                                     title="Doble click para busqueda global"
                                     style="font-family: 'Verdana', monospace; letter-spacing: 0.2px;"
                                     [innerHTML]="resaltarTexto(value, textoDestacado)"></div>
                            </ng-template>
                        </ngx-datatable-column>




                        <!-- fechaCaducidad -->
                        <ngx-datatable-column name="fechaCaducidad"
                                              [flexGrow]="15">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'fechaCaducidad')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer  link">F.caducidad</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <span (dblclick)="dblclickCampo(value)"
                                      title="Doble click para busqueda global"
                                      style="font-family: 'Verdana', monospace; letter-spacing: 0.2px;"
                                      [innerHTML]="value | iconFechaCaducada"></span>
                            </ng-template>
                        </ngx-datatable-column>


                    </ngx-datatable>
                </div>


            </div>
        </div>
    </div>

</div>