import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { IFacturacion } from 'src/app/interfaces/facturacion';

// Servicios
import { FacturacionService } from 'src/app/services/datos/facturacion.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

// entorno
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-facturacion-salidas',
  templateUrl: './salidas.component.html',
  styleUrls: ['./salidas.component.scss']
})
export class FacturacionSalidasComponent implements OnInit {

  @Input() datosCabecera: IFacturacion = {}
  @Output() totalSalidas = new EventEmitter<any>();

  public loading: boolean = false;
  public sinDatos: boolean = false;

  datosFacturacion: any[] = [];
  totales: any = {};

  constructor(
    private facturaSrv: FacturacionService,
    private notificacionesSrv: NotificacionesService
  ) { }

  ngOnInit(): void { }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['datosCabecera'] && this.datosCabecera && Object.keys(this.datosCabecera).length > 0) {
      this.enviarDatos();
    }
  }

  enviarDatos(): void {
    // this.datosCabecera.exportXls = '1';
    // this.datosCabecera.exportPdf = '1';

    console.log('__salidas/enviar() OK: ', this.datosCabecera);

    this.loading = true;
    this.facturaSrv.facSalidas(this.datosCabecera).subscribe(resp => {
      this.loading = false;
      this.sinDatos = false;

      console.log('__salidas/facturacion()', resp);

      if (resp.error) {
        this.notificacionesSrv.aviso('error', resp.mensaje);
        return;
      }

      this.datosFacturacion = resp.data.salidas;
      this.totales = resp.totales;

      // Emitir total al padre
      this.totalSalidas.emit(this.totales.salidas);

      if (!this.datosFacturacion || this.datosFacturacion.length === 0) {
        this.sinDatos = true;
      }

      console.log('__salidas/__datosFacturacion', this.datosFacturacion);

    });
  }


  abrirPdfClick() {
    console.log('__abrirPdfClick()', this.totales.pdfName);
    const urlPdf: string = environment.pdfDir + '/facturacion/' + this.totales.pdfName;
    window.open(urlPdf, '_blank');
  }

}