import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { NotificacionesService } from '../notificaciones.service';
import { UsuarioService } from './usuario.service';
import { environment } from 'src/environments/environment';

// interfaces
import { IApiResp } from 'src/app/interfaces/api-resp';
import { IFacturacion } from 'src/app/interfaces/facturacion';
import { ICliente } from 'src/app/interfaces/cliente';


@Injectable({
  providedIn: 'root'
})
export class FacturacionService {

  apiURL: string = environment.apiURL;

  constructor(
    private http: HttpClient,
    private usuarioSrv: UsuarioService,
    private notificacionesSrv: NotificacionesService,
  ) { }

  facList(id_cliente: string) {
    // Devulve el listado de facturaciones de clientes
    const datos = { 'id_cliente': id_cliente }
    const path = `${this.apiURL}/facturacion/facList`;
    return this.http.post<IApiResp>(path, datos)
  }

  facEntradas(data: IFacturacion = {}) {
    // Devulve datos de facturación de ENTRADAS de un cliente entre 2 fechas
    const path = `${this.apiURL}/facturacion/facEntradas`;
    return this.http.post<IApiResp>(path, data)
  }


  facSalidas(data: IFacturacion = {}) {
    // Devulve datos de facturación de SALIDAS de un cliente entre 2 fechas
    const path = `${this.apiURL}/facturacion/facSalidas`;
    return this.http.post<IApiResp>(path, data)
  }


  facAlmacenaje(data: IFacturacion = {}) {
    // Devulve datos de facturación de ALMACENAJE de un cliente entre 2 fechas
    const path = `${this.apiURL}/facturacion/facAlmacenaje`;
    return this.http.post<IApiResp>(path, data)
  }

  facSeguros(data: IFacturacion = {}) {
    // Devulve datos de facturación de SEGUROS para un cliente entre 2 fechas
    const path = `${this.apiURL}/facturacion/facSeguros`;
    return this.http.post<IApiResp>(path, data)
  }

  facGlobal(data: any = {}) {
    // Genera un Xlsx con los datos de daturación para un cliente entre 2 fechas
    const path = `${this.apiURL}/facturacion/facGlobal`;
    return this.http.post<IApiResp>(path, data)
  }


  facCrearXls(data: IFacturacion = {}) {
    //  Genera un archivo xlsx con las pestañas de facturación vacias
    const path = `${this.apiURL}/facturacion/facCrearXls`;
    return this.http.post<IApiResp>(path, data)
  }

  confirmaPdf(data: IFacturacion = {}) {
    //  Actualiza el registro de facturación
    const path = `${this.apiURL}/facturacion/confirmaPdf`;
    return this.http.post<IApiResp>(path, data)
  }



  getListaFac(opciones: any) {
    // Devulve la lista
    // opciones { id_cliente: string, desde: Date, hasta: Date }
    const path = `${this.apiURL}/facturacion/facList`;
    const lista = this.http.post<IApiResp>(path, opciones)
    return lista;
  }


  facDelete(id: string) {
    // Borrar un registro de Facturación
    const path = `${this.apiURL}/facturacion/facDelete/${id}`;
    return this.http.get<IApiResp>(path)
  }

}