<div *ngIf="loading"
     class="loading-overlay">
    <div class="spinner"></div>
    <h3 class="mt-3 ml-1 text-info ">Cargando...</h3>
</div>

<div class="row animated fadeIn fast">
    <div class="col-12">
        <div class="card border rounded animated fadeIn fast">


            <div class="card-body">


                <div class="row">
                    <div class="col-12 animated fadeIn fast">
                        <div class="card border border-info rounded">
                            <div class="card-body ">

                                <form (ngSubmit)="enviar()">
                                    <div class="row">

                                        <!-- cliSeleccionado -->
                                        <div class="col-3">
                                            <div class="input-group border border-dark rounded"
                                                 [ngClass]="{ 'has-danger': errores.cliSeleccionado }">
                                                <div class="input-group-addon">Cliente</div>
                                                <select class="form-control"
                                                        [(ngModel)]="cliSeleccionado"
                                                        name="cliSeleccionado"
                                                        (ngModelChange)="onClienteSelect($event)">
                                                    <option value=''>Todos...</option>
                                                    <option *ngFor="let cli of clientesList"
                                                            [value]=cli.id>
                                                        {{cli.nombre}}
                                                    </option>
                                                </select>
                                            </div>

                                            <div *ngIf="enviado && errores.cliSeleccionado"
                                                 class="form-control-feedback">
                                                <span class="text-danger">{{ errores.cliSeleccionado }}</span>
                                            </div>
                                        </div>


                                        <!-- fechaDesde -->
                                        <div class="col-2">
                                            <div class="input-group border border-dark rounded"
                                                 [ngClass]="{ 'has-danger': errores.fechaDesde }">
                                                <div class="input-group-addon">Desde</div>
                                                <input class="form-control"
                                                       type="date"
                                                       [(ngModel)]="fechaDesde"
                                                       name="fechaDesde"
                                                       required>
                                            </div>
                                            <div *ngIf="enviado && errores.fechaDesde"
                                                 class="form-control-feedback">
                                                <span class="text-danger">{{ errores.fechaDesde }}</span>
                                            </div>
                                        </div>


                                        <!-- fechaHasta -->
                                        <div class="col-2">
                                            <div class="input-group border border-dark rounded"
                                                 [ngClass]="{ 'has-danger': errores.fechaHasta }">
                                                <div class="input-group-addon">Hasta</div>
                                                <input class="form-control"
                                                       type="date"
                                                       [(ngModel)]="fechaHasta"
                                                       name="fechaHasta"
                                                       required>
                                            </div>
                                            <div *ngIf="enviado && errores.fechaHasta"
                                                 class="form-control-feedback">
                                                <span class="text-danger">{{ errores.fechaHasta }}</span>
                                            </div>
                                        </div>


                                        <!-- Submit -->
                                        <div class="col-2">
                                            <button [disabled]="loading"
                                                    type="submit"
                                                    class="ml-2 btn waves-effect waves-light btn-rounded btn-success">
                                                <i class="fa fa-spinner fa-spin"
                                                   *ngIf="loading"></i> Enviar</button>
                                        </div> 


                                        <div class="col-3">
                                            <div class="row float-right">
                                                <div class="col-sm-8">
                                                    <div class="form-group rounded">
                                                        <select class="form-control"
                                                                name="rowsLimitStr"
                                                                [(ngModel)]="rowsLimitStr"
                                                                (change)="rowsLimitChange()">
                                                            <option [value]="10">10</option>
                                                            <option [value]="50">50</option>
                                                            <option [value]="100">100</option>
                                                            <option [value]="999999">Todo</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div class="form-group float-right">
                                                        <div class="btn-group">
                                                            <button type="button"
                                                                    (click)="exportToExcel()"
                                                                    class="btn btn-outline-info btn-circle"
                                                                    title="Descargar Excel">
                                                                <i class="fa-solid fa-download"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>



                                    </div><!-- row -->
                                </form>





                            </div>
                        </div>
                    </div>
                </div> <!-- row -->




                <div class="rounded border">

                    <!-- ngx-datatable -->
                    <ngx-datatable class='bootstrap'
                                   [columnMode]="ColumnMode.flex"
                                   [loadingIndicator]="loading"
                                   [headerHeight]="70"
                                   [footerHeight]="50"
                                   [rowHeight]="50"
                                   [limit]="rowsLimit"
                                   [rows]='rows'
                                   [messages]="messages"
                                   [footerHeight]="50"
                                   [summaryRow]="true"
                                   [summaryHeight]="55"
                                   [summaryPosition]="summaryPosition">



                        <!-- id -->
                        <ngx-datatable-column name="id"
                                              [flexGrow]="5">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'id')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link">id</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                {{value}}
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- cliente.nombre -->
                        <ngx-datatable-column name="cliente.nombre"
                                              [prop]="'cliente.nombre'"
                                              [flexGrow]="11">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link mt-noSearch">Cliente</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                {{value}}
                            </ng-template>
                        </ngx-datatable-column>

                        <!-- desde -->
                        <ngx-datatable-column name="desde"
                                              [flexGrow]="7">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'desde')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link">Desde</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                {{value}}
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- hasta -->
                        <ngx-datatable-column name="hasta"
                                              [flexGrow]="7">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'hasta')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link">Hasta</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                {{value}}
                            </ng-template>
                        </ngx-datatable-column>


                        <!-- importe -->
                        <ngx-datatable-column name="importe"
                                              [flexGrow]="7"
                                              [cellClass]="'text-right'"
                                              [summaryFunc]="sumaCeldasEuro">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <input type="search"
                                       class="form-control form-control-sm"
                                       placeholder="Buscar..."
                                       (keyup)="buscar($event, 'importe')"><br>
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link"
                                        title="Importe total en el periodo">Importe</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <span>{{ value | currency:'EUR':'symbol':'1.2-2' }}</span>
                            </ng-template>
                        </ngx-datatable-column>




                        <!-- created_at -->
                        <ngx-datatable-column name="created_at"
                                              prop="created_at"
                                              [flexGrow]="7">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link mt-noSearch">Generado el</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                {{value}}
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- user.nombre -->
                        <ngx-datatable-column name="user.nombre"
                                              [prop]="'user.nombre'"
                                              [flexGrow]="11">
                            <ng-template ngx-datatable-header-template
                                         let-sort="sortFn"
                                         let-column="column">
                                <div class="header"
                                     (click)="sort()">
                                    <h6 class="pointer link mt-noSearch">Generado por</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                {{value}}
                            </ng-template>
                        </ngx-datatable-column>




                        <!-- pdf_entradas -->
                        <ngx-datatable-column name="hash"
                                              [flexGrow]="4"
                                              [sortable]="false">
                            <ng-template ngx-datatable-header-template
                                         let-column="column">
                                <div class="header">
                                    <h6 class="mt-noSearch">Entradas</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div (click)="abrirPdf(value+'_entradas.pdf')"
                                     class="link ml-3 pointer"
                                     title="Abrir pdf de entradas">
                                    <i class="fa fa-file-pdf fa-2x"></i>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>

                        <!-- pdf_devoluciones -->
                        <ngx-datatable-column name="hash"
                                              [sortable]="false"
                                              [flexGrow]="4">
                            <ng-template ngx-datatable-header-template
                                         let-column="column">
                                <div class="header">
                                    <h6 class=" link mt-noSearch">Devoluciones</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div (click)="abrirPdf(value+'_devoluciones.pdf')"
                                     class="link ml-3 pointer"
                                     title="Abrir pdf de devoluciones">
                                    <i class="fa fa-file-pdf fa-2x"></i>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>

                        <!-- pdf_salidas -->
                        <ngx-datatable-column name="hash"
                                              [sortable]="false"
                                              [flexGrow]="4">
                            <ng-template ngx-datatable-header-template
                                         let-column="column">
                                <div class="header">
                                    <h6 class="pointer link mt-noSearch">Salidas</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div (click)="abrirPdf(value+'_salidas.pdf')"
                                     class="link ml-3 pointer"
                                     title="Abrir pdf de salidas">
                                    <i class="fa fa-file-pdf fa-2x"></i>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>

                        <!-- pdf_almacenaje -->
                        <ngx-datatable-column name="hash"
                                              [sortable]="false"
                                              [flexGrow]="4">
                            <ng-template ngx-datatable-header-template
                                         let-column="column">
                                <div class="header">
                                    <h6 class="pointer link mt-noSearch">Almacenaje</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div (click)="abrirPdf(value+'_almacenaje.pdf')"
                                     class="link ml-3 pointer"
                                     title="Abrir pdf de almacenaje">
                                    <i class="fa fa-file-pdf fa-2x"></i>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>

                        <!-- pdf_seguros -->
                        <ngx-datatable-column name="hash"
                                              [sortable]="false"
                                              [flexGrow]="4">
                            <ng-template ngx-datatable-header-template
                                         let-column="column">
                                <div class="header">
                                    <h6 class="link mt-noSearch">Seguros</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div (click)="abrirPdf(value+'_seguros.pdf')"
                                     class="link ml-3 pointer"
                                     title="Abrir pdf de seguros">
                                    <i class="fa fa-file-pdf fa-2x"></i>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>

                        <!-- pdf_resumen -->
                        <ngx-datatable-column name="hash"
                                              [sortable]="false"
                                              [flexGrow]="4">
                            <ng-template ngx-datatable-header-template
                                         let-column="column">
                                <div class="header">
                                    <h6 class="link mt-noSearch">Totales</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         ngx-datatable-cell-template>
                                <div (click)="abrirPdf(value+'_resumen.pdf')"
                                     class="link ml-3 pointer"
                                     title="Abrir pdf resumen de todos los módulos">
                                    <i class="fa-regular fa-file-pdf fa-2x"></i>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- excel -->
                        <ngx-datatable-column name="hash"
                                              [sortable]="false"
                                              [flexGrow]="4">
                            <ng-template ngx-datatable-header-template
                                         let-column="column">
                                <div class="header">
                                    <h6 class=" link mt-noSearch">Xlsx</h6>
                                </div>
                            </ng-template>
                            <ng-template let-value="value"
                                         let-row="row"
                                         ngx-datatable-cell-template>
                                <div *ngIf="row.xls === '1'"
                                     (click)="abrirXls(value)"
                                     class="link mr-2 pointer"
                                     title="Abrir xlsx del periodo facturado">
                                    <i class="fa-regular fa-file-excel fa-2x"></i>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>



                        <!-- añadir / editar / borrar -->
                        <ngx-datatable-column [width]="50"
                                              [maxWidth]="100"
                                              [sortable]="false"
                                              [resizeable]="false">


                            <ng-template let-row="row"
                                         let-value="value"
                                         ngx-datatable-cell-template>

                                <a (click)="deleteRow(row)"
                                   class="link mr-2 pointer"
                                   title="Borrar">
                                    <span class="badge">B</span>
                                </a>
                            </ng-template>


                        </ngx-datatable-column>



                    </ngx-datatable>
                </div>

            </div>
        </div>
    </div>

</div>