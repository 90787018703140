import { EventEmitter, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IEntrada } from '../interfaces/entrada';
import { catchError, Subject, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UsuarioService } from './datos/usuario.service';
import { IApiResp } from '../interfaces/api-resp';

@Injectable({
  providedIn: 'root'
})
export class ModalFactEntradaService {
  apiURL: string = environment.apiURL;

  private _ocultarModal: boolean = true;
  private _entrada: any;

  entradaSubject: Subject<IEntrada> = new Subject<IEntrada>();
  modalClosed: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private http: HttpClient,
    private usuarioSrv: UsuarioService,
  ) { }


  get entrada(): any {
    return this._entrada;
  }

  set entrada(value: any) {
    this._entrada = value;
    this.entradaSubject.next(value);
  }

  get ocultarModal() {
    return this._ocultarModal;
  }


  updateFacturacion(entrada: IEntrada) {
    const path = `${this.apiURL}/entradas/updateFacturacion`;

    console.log('__updateFacturacion', entrada);

    return this.http.post<IApiResp>(path, entrada)
      .pipe(
        catchError(error => {
          this.errorAuth();
          return throwError(() => new Error(error));
        })
      )
  }



  abrirModal() {
    this._ocultarModal = false;
    console.log('__abrirModal()', this.entrada)
  }

  cerrarModal() {
    // cerrar modal y emitir 'modalClosed' para que el subscriptor se entere
    this._ocultarModal = true;
    console.log('__cerrarModal()', this.entrada)
    this.modalClosed.emit();

  }

  ///// AUX ////

  errorAuth() {
    this.usuarioSrv.logOut();
    console.log('error', environment.apiErrorAuth);
    // this.notificacionesSrv.aviso('error', environment.apiErrorAuth);
  }

}
