import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IEntrada } from 'src/app/interfaces/entrada';
import { ModalFactEntradaService } from 'src/app/services/modal-fact-entrada.service';
import { NotificacionesService } from 'src/app/services/notificaciones.service';

@Component({
  selector: 'app-modal-fact-entrada',
  templateUrl: './modal-fact-entrada.component.html',
  styleUrls: ['./modal-fact-entrada.component.scss']
})
export class ModalFactEntradaComponent implements OnInit, OnDestroy {

  @Input() entrada?: IEntrada = {};
  enviado: boolean = false;
  loading: boolean = false;

  public factForm: FormGroup = new FormGroup({});
  factSubscription: Subscription | undefined;


  constructor(
    public modalFactEntradaSrv: ModalFactEntradaService,
    private notificacionesSrv: NotificacionesService,
    private fb: FormBuilder) { }

  ngOnDestroy(): void {
    this.factSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.entrada = this.modalFactEntradaSrv.entrada;

    this.factForm = this.fb.group({
      id: [this.entrada?.id],
      devolucion: [this.entrada?.devolucion],
      fac_pales: [this.entrada?.fac_pales],
      fac_horas: [this.entrada?.fac_horas],
      fac_etiquetas: [this.entrada?.fac_etiquetas],
      contenedor: [this.entrada?.contenedor],
      fac_contenedor20: [this.entrada?.fac_contenedor20],
      fac_contenedor40: [this.entrada?.fac_contenedor40],
    })


    // Suscribirse al objeto y actualizar el formulario
    this.factSubscription = this.modalFactEntradaSrv.entradaSubject.subscribe((entrada: IEntrada) => {
      if (!entrada) return
      this.entrada = entrada

      console.log('__factSubscription entrada', this.entrada)
      this.factForm.patchValue({
        id: entrada?.id,
        idevolucion: entrada?.devolucion,
        contenedor: entrada?.contenedor,
        fac_pales: entrada?.fac_pales,
        fac_contenedor20: entrada?.fac_contenedor20,
        fac_contenedor40: entrada?.fac_contenedor40,
        fac_horas: entrada?.fac_horas,
        fac_etiquetas: entrada?.fac_etiquetas,
      });
      this.enviado = false
    });

  }


  cerrarModal() {
    this.modalFactEntradaSrv.entrada = undefined;
    this.modalFactEntradaSrv.cerrarModal();
  }

  cerrarModalClickOutside(event: MouseEvent) {
    // Cerrar modal al hacer click fuera del modal  
    if ((event.target as HTMLElement).classList.contains('modal')) {
      // this.cerrarModal();
    }
  }



  get f() {
    return this.factForm.controls;
  }


  enviar() {
    this.enviado = true;
    if (this.factForm.invalid) {
      console.log("ERRORES:", this.factForm);
      return;
    }
    // Todo ok, enviar al BackEnd

    this.loading = true;

    // Actualizar fac_contenedor20 y fac_contenedor40 en función del valor contenedor
    const contenedor = this.factForm.value.contenedor;
    this.factForm.patchValue({
      fac_contenedor20: contenedor == '20 pies' ? 1 : 0,
      fac_contenedor40: contenedor == '40 pies' ? 1 : 0,
    });

    this.modalFactEntradaSrv.updateFacturacion(this.factForm.value)
      .subscribe(resp => {
        this.loading = false;
        if (resp.error) {
          this.notificacionesSrv.aviso('error', resp.mensaje);
          for (let controlName in resp.mensaje) {
            this.factForm.get(controlName)!.setErrors({ 'apiError': resp.mensaje[controlName] });
          }
        } else {
          this.notificacionesSrv.aviso('success', resp.mensaje);
          this.cerrarModal();
          return
        }
      })


  }


  ///   AUX   ///



}
